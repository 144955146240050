import {useState} from "react";
import {SidebarContext} from "./SidebarContext";

export const SidebarProvider = ({children}) => {
	const [sidebarOpen, setSidebarOpen] = useState(true);
	return (
		<SidebarContext.Provider value={{
			sidebarOpen,
			setSidebarOpen}}>
			{children}
		</SidebarContext.Provider>
	);
}