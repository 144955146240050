import {Fragment, Component} from 'react';
import SelectField from '../SelectField/SelectField';
import {Row, Col} from '../Grid';
import DateTimeField from '../DateTimeField/DateTimeField';
import TextInput from '../TextInput/TextInput';
import NumberField from '../NumberField/NumberField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import InputLabel from '@material-ui/core/InputLabel';
import {withStyles} from '@material-ui/core/styles';
import RemoveIcon from '@material-ui/icons/Close';
import FormHelperText from '@material-ui/core/FormHelperText';


const styles = () => ({
	"inputs_cont": {
		"border": 'solid 1px  ##1E2832',
		"padding": '1rem',
	},
	"label": {
		"position": "absolute",
		"marginTop": "-10px",
		"marginLeft": "10px",
		"padding": "5px",
	},
	"valueCont": {
		"display": "flex",
		"alignItems": "center",
	},
	"removeIcon": {
		"cursor": "pointer",
		"& svg": {
			"marginTop": "50%",
			"marginLeft": "0.5rem", 
		}
	},
	"helperText": {
		"justifyContent": 'flex-end',
		"margin": '1rem',
		"display": 'flex',
	}
});

const input_types = [
	{"title": 'Text', "value": 'text'},
	{"title": 'Date', "value": 'date'},
	{"title": 'Date Time', "value": 'datetime'},
	{"title": 'Number', "value": 'number'},
];

class JSONField extends Component {
	constructor (props) {
		super(props);
		this.state = {
			'fields': this.getSchemaFromValue(props.value),
		};
	}

	getSchemaFromValue (value) {
		if (!value) return [];
		return Object.keys(value).map((k) => ({"type": 'text', "key": k, "msg": '', "valid": true}));
	}

	componentDidUpdate (_prevProps) {
		// TODO: handle the case when the value is updated from outside the form
		// (challenge how to tell if the new value came form outside form or because we called this.props.onChange )
	}

  handleTypeChange = (idx, value, valid, msg) => {
  	const json_data = this.clear_json_key(idx);

  	this.props.onChange(this.props.name, json_data);
  	this.updateField(idx, {"type": value, valid, msg});
  }

  handleValueChange = (idx, name, value, valid, msg) => {
  	const json_valid = this.validate_all(idx, valid);
  	const json_data = {...this.props.value, [name]: value};
  	this.props.onChange(this.props.name, json_data, json_valid, json_valid ? '' : 'Invalid JSON data');

  	this.updateField(idx, {msg, valid});
  }

  handleKeyChange = (idx, value, valid, msg) => {
  	const new_key = value;
  	const json_data = this.clear_json_key(idx);

  	if (new_key) json_data[new_key] = '';

  	const json_valid = this.validate_all(idx, valid, new_key);

  	this.updateField(idx, {"key": new_key, valid, msg});

  	this.props.onChange(this.props.name, json_data, json_valid, json_valid ? '' : 'Invalid data');
  }

  updateField = (idx, attrs) => {
  	this.setState((prevState) => {
  		const fields = prevState.fields.map((f, i) => (i === idx ? {...f, ...attrs} : {...f}));
  		return {fields};
  	});
  }
  
  clear_json_key = (idx) => {
  	let json_data = this.props.value;
  	const old_key = this.state.fields[idx].key;
  	delete json_data[old_key];
  	return json_data;
  }

  validate_all = (idx, field_valid, new_key = null) => {
  	const fields_valid = this.state.fields.every((f, i) => (i === idx ? field_valid : f.valid));
  	return this.keys_are_unique(idx, new_key) && fields_valid;
  }

  keys_are_unique = (idx, new_key) => {
  	let all_keys;
  	if (new_key) {
  		const other_keys = this.state.fields.filter((f, i) => i !== idx).map((f) => f.key);
  		all_keys = [...other_keys, new_key];
  	}
  	else {
  		all_keys = this.state.fields.map((f) => f.key);
  	}

  	return new Set(all_keys).size === all_keys.length;
  }

  addField = () => {
  	this.setState((prevState) => ({
  		"fields": [...prevState.fields, {"type": 'text', "msg": '', "valid": true, "key": ''}],
  	}));
  }

  removeField = (field, idx) => {
  	const json_data = this.clear_json_key(idx);
  	this.props.onChange(this.props.name, json_data);

  	this.setState((prevState) => {
  		const fields = prevState.fields.filter((f, i) => i !== idx);
  		return {fields};
  	});
  }

  renderInput = (field, idx, data, common) => {
  	const {type, key} = field;
  	if (!key) {
  		return null;
  	}

  	common = {
  		...common,
  		"label": "Value",
  		"required": true,
  		"name": key,
  		"onChange": (name, value, valid, msg) => {
  			this.handleValueChange(idx, name, value, valid, msg);
  		},
  		"value": data[key] || "",
  	};

  	if (type === 'text') {
  		return <TextInput
  			{...common}
  			multiline={true}
  		/>
  	}
  	else if (type === 'date' || type === 'datetime') {
  		return <DateTimeField
  			{...common}
  			type={type}
  		/>
  	}
  	else if (type === 'number') {
  		return <NumberField
  			{...common}
  		/>
  	}
  	else {
  		return null;
  	}
  }

  render () {
  	const {fields} = this.state;
  	const {label, value, style, className, margin, classes, helperText} = this.props;
  	const common = {style, className, margin};

  	return (
  		<Fragment>
  			<br/>
  			<InputLabel className={classes.label}>{label}</InputLabel>
        
  			<div className={classes.inputs_cont}>
  				{fields.map((field, idx) => <Row key={idx}>
  						<Col xs={12} md={4}>
  							<SelectField
  								{...common}
  								required
  								label="Type"
  								value={field.type}
  								options={input_types}
  								name={field.key}
  								onChange={(name, value, valid, msg) => this.handleTypeChange(idx, value, valid, msg)}
  							/>
  						</Col>

  						<Col xs={12} md={4} >
  							<TextInput
  								{...common}
  								required
  								type='text'
  								name="field_key"
  								label="Field Name"
  								value={field.key}
  								onChange={(name, value, valid, msg) => this.handleKeyChange(idx, value, valid, msg)}
  							/>
  						</Col>

  						<Col xs={12} md={4} className={classes.valueCont}>
  							{this.renderInput(field, idx, value, common)}

  							<div className={classes.removeIcon} onClick={() => this.removeField(field, idx)}>
  								<RemoveIcon />
  							</div>
  						</Col>
  					</Row>
  				)}

  				<div style={{"textAlign": 'center'}}>
  					<Fab disableRipple color='default' size="small" onClick={this.addField} style={{"marginLeft": 'auto'}}>
  						<AddIcon />
  					</Fab>
  				</div>

  				{helperText &&
            <FormHelperText className={classes.helperText} error>
            	{ helperText }
            </FormHelperText>
  				}
  			</div>
  		</Fragment>
  	);
  }
}

export default withStyles(styles)(JSONField);
