import {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import SwitchMUI from '@material-ui/core/Switch';


const styles = (theme) => ({
	"label": {
		"color": `${theme.palette.text.primary} !important`,
	},
	"disabled_switch_on": {
		"color": `${theme.palette.primary.main} !important`,
	}
});

class Switch extends Component {
  onChange = (e) => {
  	const value = e.target.checked;
  	this.props.onChange(this.props.name, value, true, '');
  }

  render () {
  	const { 
  		// eslint-disable-next-line no-unused-vars
  		classes, onChange, helperText, label, value, inputProps, InputProps, disabled, viewMode, 
  		...restProps
  	} = this.props;

  	return (
  		<FormControl {...restProps}>
  			<FormControlLabel
  				control={
  					<SwitchMUI
  						checked={Boolean(value)}
  						onChange={this.onChange}
  						color='primary'
  						disabled={Boolean(disabled) || viewMode}
  						inputProps={inputProps}
  						classes={{
  							"disabled": (viewMode && Boolean(value)) ? classes.disabled_switch_on : '',
  						}}
  					/>
  				}
  				label={label}
  				classes={{
  					"disabled": viewMode ? classes.label : '',
  				}}
  			/>
  			{helperText && <FormHelperText>{ helperText }</FormHelperText>}
  		</FormControl>
  	)
  }
}

export default withStyles(styles)(Switch);
