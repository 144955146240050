import {API_HOST_ROOT} from 'config/api-config';
import {getCountryAuthMethods} from 'constants/auth_methods';
import {startCase} from 'lodash';
import Company from './Company';

export default class User {
  id!: number;
  name: string;
  first_name: string;
  last_name: string;
  profile_id: number;
	is_ubo?: boolean;
  identity_reference: string;
	identity_type: string;
  country?: string;
  identity_verified?: boolean;
  company?: Company | null;
  financier?: any;
  is_treyd_user?: boolean;
  financier_id?: number;
  confirmed_at?: Date;
  invitation_sent_at?: Date;
  confirmation_sent_at?: Date;
  avatar_url?: string;
  email?: string;
  can_sign_alone?: boolean;
  is_signatory?: boolean;
  is_board_member?: boolean;
  email_confirmed?: boolean;
  date_joined?: Date;
  phone_no?: number;
  phone_code?: number;
	company_id!: number;

	constructor (data: any) {
  	Object.assign(this, data);
  	this.profile_id = data.profile_id;
  	this.identity_reference = data.identity_reference;
  	this.identity_type = data.identity_type;
  	this.is_ubo = data.is_ubo;
  	this.country = data.country || this.default_country;
  	this.company = this.company ? new Company(this.company) : null;
  	this.is_treyd_user = data.is_treyd_user;
  	this.financier_id = data.financier_id;
  	this.confirmed_at = data.confirmed_at;
  	this.invitation_sent_at = data.invitation_sent_at;
  	this.confirmation_sent_at = data.confirmation_sent_at;
  	this.avatar_url = data.avatar_url;
  	this.can_sign_alone = data.can_sign_alone;
  	this.is_signatory = data.is_signatory;
  	this.is_board_member = data.is_board_member;
  	this.email_confirmed = data.email_confirmed;
  	this.name = startCase(data.name?.toLowerCase());
  	this.first_name = data.first_name;
  	this.last_name = data.last_name;
  	this.date_joined = data.date_joined;
  	this.phone_no = data.phone_no;
  	this.phone_code = data.phone_code;
		this.identity_type = data.identity_type;
	}

	get is_su () {
  	return this.is_treyd_user;
	}

	get is_financer () {
  	return Boolean(this.financier_id);
	}

	get invite_status () {
  	return invite_status(this);
	}

	get avatar () {
  	return this.avatar_url ? API_HOST_ROOT + this.avatar_url : '/assets/img/blank-profile-picture.svg';
	}

	get entity () {
  	return this.is_financer ? this.financier : this.company;
	}
	get entity_key () {
  	return this.is_financer ? 'financer' : 'company';
	}

	get default_country (): string {
  	return this.entity?.country;
	}

	get default_auth_method (): string {
  	return getCountryAuthMethods(this.default_country)?.[0];
	}

	get display_name (): string {
  	const first_name = this.first_name?.split(" ")?.[0]?.length >= 2 ? this.first_name.split(" ")[0] : this.first_name ;
  	return startCase(first_name?.length > 2 ? first_name : this.name);
	}


	is_member (entity_id?: number) {
  	if (!entity_id) {
  		console.warn('invalid entity id', entity_id);
  		return false;
  	}
  	else {
  		return this.entity?.id === entity_id;
  	}
	}

	// Currently this is used for none view access
	check_access (entity_id?: number) {
  	return this.is_su || this.is_member(entity_id);
	}


	can_edit (company_id: number) {
  	return this.is_su || this.is_member(company_id);
	}



	has_power_of_attorney (powerOfAttorneyList: any) {
  	return powerOfAttorneyList?.map((attorney: any) => attorney?.user?.identity_reference)?.includes(this.identity_reference);
	}
  
}


export const invite_status = (user: User) : string  =>{
	if (user.confirmed_at) {
		return 'Confirmed';
	}
	else if (user.invitation_sent_at) {
		return 'Invitation Pending';
	}
	else if (user.confirmation_sent_at) {
		return 'Pending Confirmation';
	}
	else {
		return 'No Invitation Sent';
	}
}

