import {createMuiTheme, createStyles} from '@material-ui/core/styles';


const fontSize = "0.8rem";
const inputPadding = '0.8rem';

export const palette = {
	"primary": {
		"main": 'rgba(30,40,50,0.9)', //black
		"dark": 'rgba(30,40,50,1)',
		"light": 'rgba(30,40,50,0.5)',
		"contrastText": '#ffffff',
	},
	"secondary": {
		"main": '#AFF5A3',
		// dark:'#66CD55',
		"contrastText": '#014C1B',
	},
	"text": {
		"primary": 'rgba(30,40,50,1)',
		"secondary": 'rgba(30,40,50,0.5)',
	},
	"error": {
		"main": 'rgba(238, 123, 119,1)'
	},
	"tonalOffset": 0.1,
};

export const defaultThemeValues = {
	palette,
	"typography": {
		// Use the system font instead of the default Roboto font.
		"fontFamily": ["TWK Lausanne", 'sans-serif'].join(','),
	},
	"overrides": {
		// Style sheet name
		'MuiTypography': createStyles({
			"h1": {
				"fontWeight": '400',
				"fontSize": '1.75rem',
				"paddingBottom": '10px',
				"display": 'inline-block',
			},

			"h2": {
				"fontWeight": '400',
				"fontSize": '1.4rem',
			},
			"h3": {
				"fontWeight": '400',
				"fontSize": '1.3rem',
			},
			"h4": {
				"fontWeight": '400',
				"fontSize": '1.15rem',
			},
			"h5": {
				"fontWeight": '400',
				"fontSize": '1.1rem',
			},
			"h6": {
				"fontWeight": '400',
				"fontSize": '1rem',
			},
			"body1": {
				fontSize,
			},
			"button": {
				"fontWeight": '400',
				"fontSize": '1rem',
				"textTransform": 'none',
			},
		}),
		"MuiButton": {
			"root": {
				"textTransform": 'none',
				"fontWeight": '400',
			}
		},
		"MuiFab": {
			"root": {
				"textTransform": 'none',
				"fontWeight": '400',
			}
		},
		"MuiPickersToolbar": {
			"toolbar": {
				"backgroundColor": palette.secondary.main
			}
		},
		"MuiPickersToolbarText": {
			"toolbarBtnSelected": {
				"color": palette.secondary.contrastText
			},
			"toolbarTxt": {
				"color": palette.secondary.contrastText
			}
		},
		"MuiOutlinedInput": createStyles({
			"root": {
				'&$focused $notchedOutline': {
					"borderColor": '#676767',
				},
			},
			"input": {
				fontSize,
				"padding": inputPadding,
			},
			"multiline": {
				fontSize,
				"padding": '0.8rem',
			}
		}),
		"MuiInputBase": createStyles({
			"input": {
				fontSize,
				"padding": inputPadding,
			},
		}),
		"MuiSelect": createStyles({
			"root": {
				fontSize,
				"paddingTop": '0.7rem',
				"paddingBottom": '0.7rem',
			}
		}),
		"MuiInputLabel": {
			"root": {
				"background": 'white',
				"padding": '0 0px',
				"color": "rgba(30, 40, 50, 0.5);",
				"&$focused": { // increase the specificity for the pseudo class
					"color": 'black',
				}
			},
			"outlined": {
				"background": 'white',
			}
		},
		"MuiFormLabel": createStyles({
			"root": {
				"color": "rgba(30, 40, 50, 0.5);",
				"&$focused": { // increase the specificity for the pseudo class
					"color": 'black',
				}
			},
			"asterisk": {
				"color": '#FF0000'
			},
		}),
		"MuiChip": createStyles({
			"root": {
				fontSize,
			},
		}),
		"MuiListItemText": createStyles({
			"root": {
				fontSize,
			}
		}),
		"MuiTableRow": {
			"hover": {
				'&:hover': {
					"backgroundColor": 'rgba(0,0,0,0.01) !important',
				},
			}
		},
		"MuiTableCell": createStyles({
			"body": {
				"color": 'rgba(30,40,50,0.5)',
			},
			"root": {
				fontSize,
				"padding": '3px 3px 3px 15px',
				"color": 'rgba(30,40,50,0.5)',
			},
			"head": {
				"fontWeight": '400',
				"color": 'rgba(30,40,50,0.5)',
			},
			"stickyHeader": {
				"color": 'rgba(30,40,50,0.5)',
				"backgroundColor": 'white',
			},
			"footer": {
				"borderBottom": 'none',
			}
		}),
		"MuiTablePagination": createStyles({
			"toolbar": {
				"overflow": 'hidden',
				"height": '30px',
				"minHeight": '30px',
				"paddingRight": '2px',
			}
		}),
		"MUIDataTableHeadCell": createStyles({
			"sortAction": {
				"display": 'block',
			},
		}
		),
		"MuiStepIcon": createStyles({
			"root": {
				// color: 'gray',
				'&$completed': {
					"color": 'gray',
				},
				'&$active': {
					"color": 'black'
				}
			},
			"active": {},

		}),
		"MuiTimelineDot": createStyles({
			// Name of the rule
			"defaultSecondary": {
				// Some CSS
				"backgroundColor": 'var(--theme-middle-green)',
			},
		}),
		"MuiTimelineContent": {
			// Name of the rule
			"root": {
				// Some CSS
				"padding": '6px 6px 6px 3px',
			},
		},
		"MuiTimelineOppositeContent": {
			// Name of the rule
			"root": {
				// Some CSS
				"padding": '6px 3px 6px 6px',
			},
		},
		"MuiTooltip": createStyles({
			"tooltip": {
				fontSize,
			},

		}),
		"MuiPaper": createStyles({
			"root": {
				"border": '1px solid rgba(0, 0, 0, 0.1)',
			},
			"elevation0": {
				"boxShadow": 'none',
			},
			"elevation1": {
				"boxShadow": 'none',
			},
			// elevation2: {
			//   boxShadow: 'none',
			// },
			"elevation3": {
				"boxShadow": 'none',
			},
			"elevation4": {
				"boxShadow": 'none',
			},
			"rounded": {
				"borderRadius": '0.75rem',
			},

		}),
		"MuiStepLabel": createStyles({
			"active": {
				"fontWeight": '300 !important',
			},
			"completed": {
				"fontWeight": '300 !important',
			},
		}),

	},
	// For some reason overriding the props is not working, currently it's done manually
	"props": {
		// Name of the component
		"MuiTextField": {
			// The default props to change
			"variant": "outlined",
		},
		"MuiInputLabel": {
			"shrink": true,
		},
		"MuiTextBase": {
			"variant": "outlined",
		},
	},
}

export const theme = createMuiTheme(defaultThemeValues);

export const fieldStyle = {
	"width": "100%",
}