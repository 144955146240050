import {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {configureStore} from './redux/store';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {FormProvider} from "components/dataflow-forms";
import {theme, fieldStyle} from './MuiTheme';
import {PersistGate} from 'redux-persist/integration/react'
import getApolloClient from 'providers/apollo_client';
import {ApolloProvider} from '@apollo/client';
import ErrorsBoundary from 'components/dataflow-forms/ErrorsBoundary';
import {SidebarProvider} from 'containers/navs/SidebarProvider';
import {LoggingProvider} from 'providers/loggingServiceProvider';
import {FeatureFlagProvider} from 'providers/feature_flags/feature_flags_provider';

const client = getApolloClient();

const App = lazy(() => import(/* webpackChunkName: "App" */'./App'));

const {store, persistor} = configureStore();

ReactDOM.render(
	<Provider store={store}>
		<PersistGate persistor={persistor} loading={<div className="loading" />} >
			<LoggingProvider>
				<ApolloProvider client={client}>
					<FeatureFlagProvider>
						<Suspense fallback={<div className="loading" />}>
							<MuiThemeProvider theme={theme}>
								<FormProvider fieldStyle={fieldStyle}>
									<ErrorsBoundary>
										<SidebarProvider>
											<App />
										</SidebarProvider>
									</ErrorsBoundary>
								</FormProvider>
							</MuiThemeProvider>
						</Suspense>

					</FeatureFlagProvider>
				</ApolloProvider>
			</LoggingProvider>
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
