import {Component} from 'react';
import validate from '../Form/lib/validate';
import TextField from '@material-ui/core/TextField';



class TextInput extends Component {
  onChange = (e) => {
  	const {name, value} = e.target;
  	const {valid, msg} = validate(value, this.props);
  	this.props.onChange(name, value, valid, msg);
  }

  render () {
  	// eslint-disable-next-line no-unused-vars
  	let {onChange, label, value, validate, ...restProps} = this.props;

  	return (
  		<TextField
  			InputLabelProps={{
  				"shrink": true,
  			}}
  			label={label}
  			value={value}
  			onChange={this.onChange}
  			{...restProps}
  		/>
  	)
  }
}

export default TextInput;
