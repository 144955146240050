import {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Portal from '@material-ui/core/Portal';


const styles = (theme) => ({
	"paper": {
		"maxHeight": '500px',
		"position": 'absolute',
		"overflowY": 'auto',
		"zIndex": 1500,
		"left": theme.spacing(1),
	},
});

class AutoCompletePopper extends Component {
	constructor (props) {
		super(props);
		this.state = {
			"poperStyle": {},
		}
	}

	componentDidMount () {
		const {height, width, top, left} = this.props.inputRef.current.parentNode.getBoundingClientRect();

		this.setState({
			"poperStyle": {
				"left": left + window.pageXOffset,
				width,
				"top": height + top + window.pageYOffset,
			}
		});
	}

	render () {
		const {children, classes} = this.props;
    
		return (
			<Portal>
				<Paper className={classes.paper} style={this.state.poperStyle} elevation={2}>
					{children}
				</Paper>
			</Portal>
		)
	}
}


export default withStyles(styles)(AutoCompletePopper);
