import {ErpIntegration, ErpIntegrationInitialState} from 'models/ErpIntegration';
import {
	SET_CREDENTIALS,
	SET_ERP,
	SET_CURRENT_COMPANY,
	SET_USER_COMPANIES
} from '../actions';


export const setCredentials = (creds:any) => ({
	"type": SET_CREDENTIALS,
	creds,
});

export const setUserCompanies = (userCompanies:any) => ({
	"type": SET_USER_COMPANIES,
	userCompanies,
});

export const setCurrentCompany = (current_company:any) => ({
	"type": SET_CURRENT_COMPANY,
	current_company,
});

export const setErpIntegration = (erp_integration:ErpIntegration|ErpIntegrationInitialState) => ({
	"type": SET_ERP,
	erp_integration,
});