import {safelyCreateDate} from './safelyCreateDate';

const formatOptions = {
	"date": {"dateStyle": 'short'},
	"time": {"timeStyle": 'medium'},
	"datetime": {"dateStyle": 'short', "timeStyle": 'short'},
}





export default function displayDT (dt, type, locale = "en-SE") {
	let dateType = type
	if (!dt) {
		return '';
	}
	if (type === 'date-time') {
		dateType = 'datetime';
	}
	//if dt is string create Date object then format it
	let date = dt
	if (typeof dt === 'string') {
		date = safelyCreateDate(dt)
	}
	try {
		return new Intl.DateTimeFormat(locale, formatOptions[dateType]).format(date)
	} catch (e) {
		console.error('dt :>> ', dt);
		return 'invalid-date';
	}
}
window.displayDT = displayDT;
// function getLang() {
//   let local = "en-US";
//   if (navigator.languages != undefined) 
//     local= navigator.languages[0]; 
//   else {
//     local = navigator.language;
//   }
//   const [lang, country] = local.split('-');
  
//   return {local, lang, country};
// }

// const date = new Date()
// //format "YYYY-MM-DD"
// Intl.DateTimeFormat("en-US", {year: "numeric", month: "2-digit", day: "2-digit"}).format(date)
// //format "YYYY-MM-DD HH:mm"
// Intl.DateTimeFormat("en-US", {year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit"}).format(date)
