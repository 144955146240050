import countires from 'i18n-iso-countries';
import en_countires from 'i18n-iso-countries/langs/en.json'

countires.registerLocale(en_countires);

export const countriesEnum = []
export const countriesEnumNames = [];

Object.entries(countires.getNames("en")).forEach(([key, value]) => {
	countriesEnum.push(key.toLowerCase());
	countriesEnumNames.push(value);
})

export const countiresOptions = countriesEnumNames.map((countryName, idx)=>({
	"title": countryName,
	"value": countriesEnum[idx].toLowerCase(),
}))

export const get_country_name = (country_code) => (country_code ? countiresOptions.find((country) => country.value === country_code.toLowerCase(country_code)) : "")
