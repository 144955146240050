export default function getClassNames (defaultClassName, classes, conditions) {
	const className = [];
	if(defaultClassName) {
		className.push(defaultClassName);
	}

	for (let cls in conditions) {
		if(conditions[cls]) {
			className.push(classes[cls]);
		}
	}
	return className.join(' ');
}