import {
	SET_COUNTRIES, SET_CURRENCIES, SET_DEAL_STATUSES, SET_USER_STATUSES
} from '../actions';

const init_general_data = {
	"countries": [],
	"statuses": [],
	"deal_statuses": [],
	"currencies": [],
};

// eslint-disable-next-line default-param-last
export default function generalDataReducer (state = init_general_data, action) {
	if (action.type === SET_COUNTRIES) {
		return {...state, "countries": [...action.countries]};
	}
	else if (action.type === SET_USER_STATUSES) {
		return {...state, "statuses": [...action.statuses]};
	}
	else if (action.type === SET_CURRENCIES) {
		return {...state, "currencies": [...action.currencies]};
	}
	else if (action.type === SET_DEAL_STATUSES) {
		return {...state, "deal_statuses": [...action.deal_statuses]};
	}
	return state;
};
