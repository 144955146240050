import validate from './validate';
import * as R from 'ramda';


export const dataValid = (data) => {
	for (let key in data) {
		if (!data[key].valid) return false;
	}
	return true;
}

// eslint-disable-next-line default-param-last
export default function validate_data_schema (schema = [], data, formatted = true) {
	const new_data = {};
	schema
		.filter((field) => (field.type !== 'section') && !field.view_only)
		.forEach((field) => {
			let field_data;
			if (formatted) {
				field_data = data[field.key] ? R.clone(data[field.key]) : {};
			}
			else {
				field_data = {"value": data[field.key]};
			}

			const {valid, msg} = field.disabled ? {"valid": true, "msg": ""} : validate(field_data.value, field);

			field_data.valid = valid;
			field_data.msg = msg;
			new_data[field.key] = field_data;
		});

	const valid = dataValid(new_data);
	return {"data": new_data, valid};
}
