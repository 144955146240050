import request from './requestWrapper';


export default function save (url, data, method, options = {}) {
	const {noToast, noToastOnSuccess, ...rest_options} = options;
	return request({
		"baseURL": options.baseURL,
		method,
		url,
		"headers": {
			'content-type': 'application/json',
		},
		data,
		noToast,
		noToastOnSuccess,
		...rest_options
	});
}
