import validateNumber from './validateNumber';
import validateEmail from './validateEmail';
import validate_data_schema from './validate_data_schema';


export default function validate (value, field) {
	let result = {"valid": true, "msg": ''};
	const {type, required} = field;

	const valueExists = Boolean(value) || (value === 0) ? true : false; 

	if (type === "multipleSelect") {
		if(!valueExists || (value && value.length === 0)) {
			if(required) {
				result.valid = false;
				result.msg = 'Please enter a valid value';
			}
		} 
	}
	else if (type === 'multipleForm') {
		if (required && (!valueExists || (value && value.length === 0))) {
			result.valid = false;
			result.msg = 'Please enter a valid value';
		}
		else {
			// If the field is valid we make sure that the nested data is too
			const nested_valid = value.every((v) => {
				const result = validate_data_schema(field.schema, v, false);
				return result.valid;
			});
			result.valid = nested_valid;
			result.msg = result.valid ? '' : 'Please enter a valid value';
		}
	}
	else if (type === 'file') {
		if (required) {
			if (!valueExists || (!value.file_data && !value.url)) {
				result.valid = false;
				result.msg = 'Please enter a valid value';
			}
		}
	}
	else if (!valueExists) {
		if(required) {
			result.valid = false;
			result.msg = 'Please enter a valid value';
		}
	}
	else if (valueExists) {
		if (field.validate) {
			return field.validate(value, field);
		}
		else if (['number', 'integer', 'float', 'decimal'].includes(type)) {
			result = validateNumber(value, field);
		}
		else if(type === 'email') {
			result = validateEmail(value, field);
		}
	}
	return result;
}
