import {createMuiTheme} from "@material-ui/core";
import {defaultThemeValues, palette} from 'MuiTheme';
import {flow, cloneDeep, merge} from 'lodash/fp';
export const ThemeColors = () => {
	let rootStyle = getComputedStyle(document.body);
	return {
		"themeColor1": rootStyle.getPropertyValue("--theme-color-1").trim(),
		"themeColor2": rootStyle.getPropertyValue("--theme-color-2").trim(),
		"themeColor3": rootStyle.getPropertyValue("--theme-color-3").trim(),
		"themeColor4": rootStyle.getPropertyValue("--theme-color-4").trim(),
		"themeColor5": rootStyle.getPropertyValue("--theme-color-5").trim(),
		"themeColor6": rootStyle.getPropertyValue("--theme-color-6").trim(),
		"themeColor1_10": rootStyle.getPropertyValue("--theme-color-1-10").trim(),
		"themeColor2_10": rootStyle.getPropertyValue("--theme-color-2-10").trim(),
		"themeColor3_10": rootStyle.getPropertyValue("--theme-color-3-10").trim(),
		"themeColor4_10": rootStyle.getPropertyValue("--theme-color-3-10").trim(),
		"themeColor5_10": rootStyle.getPropertyValue("--theme-color-3-10").trim(),
		"themeColor6_10": rootStyle.getPropertyValue("--theme-color-3-10").trim(),
		"primaryColor": rootStyle.getPropertyValue("--primary-color").trim(),
		"foregroundColor": rootStyle.getPropertyValue("--foreground-color").trim(),
		"separatorColor": rootStyle.getPropertyValue("--separator-color").trim()
	}
}


export const TreydPalette = () => {
	let rootStyle = getComputedStyle(document.body);
	return {
		"error": rootStyle.getPropertyValue("--theme-error").trim(),
		"yellow": {
			"light": rootStyle.getPropertyValue("--theme-light-yellow").trim(),
			"middle": rootStyle.getPropertyValue("--theme-middle-yellow").trim(),
			"dark": rootStyle.getPropertyValue("--theme-dark-yellow").trim(),
			"darkest": rootStyle.getPropertyValue("--theme-darkest-yellow").trim(),
			"text": rootStyle.getPropertyValue("--theme-darkest-yellow").trim(),
			"text_light": rootStyle.getPropertyValue("--theme-dark-yellow").trim(),

		},
		"green": {
			"light": rootStyle.getPropertyValue("--theme-light-green").trim(),
			"middle": rootStyle.getPropertyValue("--theme-middle-green").trim(),
			"dark": rootStyle.getPropertyValue("--theme-dark-green").trim(),
			"darkest": rootStyle.getPropertyValue("--theme-darkest-green").trim(),
			"text": rootStyle.getPropertyValue("--theme-dark-green").trim(),
			"text_light": rootStyle.getPropertyValue("--theme-middle-green").trim(),
		},
		"blue": {
			"opaque": rootStyle.getPropertyValue("--theme-opaque-blue").trim(),
			"light": rootStyle.getPropertyValue("--theme-light-blue").trim(),
			"middle": rootStyle.getPropertyValue("--theme-middle-blue").trim(),
			"dark": rootStyle.getPropertyValue("--theme-dark-blue").trim(),
			"darkest": rootStyle.getPropertyValue("--theme-darkest-blue").trim(),
			"text": rootStyle.getPropertyValue("--theme-darkest-blue").trim(),
			"text_light": rootStyle.getPropertyValue("--theme-dark-blue").trim(),
		},
		"red": {
			"light": rootStyle.getPropertyValue("--theme-light-red").trim(),
			"text": rootStyle.getPropertyValue("--theme-text-red").trim(),
		},
		"others": {
			"disabledGrey": rootStyle.getPropertyValue("--theme-disabled-grey").trim(),
			"lightGrey": rootStyle.getPropertyValue("--theme-light-grey").trim(),
			"grey": rootStyle.getPropertyValue("--theme-grey").trim(),
			"black": rootStyle.getPropertyValue("--theme-black").trim(),
			"white": rootStyle.getPropertyValue("--theme-white").trim(),
		},
	}
}

export const ColorsEnum = {
	"YELLOW": 'yellow',
	"GREEN": 'green',
	"BLUE": 'blue',
};

export const getSecondaryColorTheme = (secondaryColor) => {
	const treyd_palette = TreydPalette();
	const secondaryPallete = {
		"main": treyd_palette[secondaryColor].light,
		"contrastText": treyd_palette[secondaryColor].text,
	}
	const theme = createMuiTheme(
		flow(cloneDeep, merge)(defaultThemeValues)({
			"palette": {
				"secondary": secondaryPallete,
			}
		})
	);
	return theme;
}

export const ParagraphsTheme = () => {

	const theme = createMuiTheme(
		flow(cloneDeep, merge)(defaultThemeValues)({
			"overrides": {
				"MuiInputLabel": {
					"root": {
						"color": palette.text.primary,
					},
				},
				"MuiFormLabel": {
					"root": {
						"color": palette.text.primary,
					},
				},
				"MuiInputBase": {
					"input": {
						"color": palette.text.secondary,
					},
				},
			},
			//For complete usage you can add overrides for secondary colors
		})
	);
	return theme
}