import axios from 'axios';
import handleError from './handleError';
import handleSuccess from './handleSuccess';


const axiosInstance = axios.create({});

axiosInstance.interceptors.response.use(handleSuccess, handleError);

export default axiosInstance;
