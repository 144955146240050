import {combineReducers} from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import credentials, {erpIntegration, currentCompany, userCompanies} from './credentials/reducer';
import forms from './forms/reducer';
import generalData from './generalData/reducer';
import deals from './deals/reducer';


const reducers = combineReducers({
	menu,
	settings,
	credentials,
	userCompanies,
	currentCompany,
	erpIntegration,
	forms,
	generalData,
	deals,
});

export default reducers;