import {
	CHANGE_LOCALE,
	SET_AUTH_METHOD,
	SET_TABLE_USER_PREF,
} from '../actions';


export const changeLocale = (locale, localeCountry, lang) => ({
	"type": CHANGE_LOCALE,
	locale,
	localeCountry,
	lang,
});

export const setAuthMethod = (auth_method) => ({
	"type": SET_AUTH_METHOD,
	auth_method,
});

export const setUserTablePrefrences = (table_name, table_user_pref) => ({
	"type": SET_TABLE_USER_PREF,
	table_name,
	table_user_pref
});

