import {Fragment} from 'react';
import {
	Modal,
	ModalBody,
	ModalHeader,
} from "reactstrap";
import PDFViewWrapper from "components/PDFViewWrapper";

export const CustomModal = ({is_open, toggle, children, title, modalProps}) => (
	<Modal isOpen={is_open} toggle={toggle} size="lg" {...modalProps}>
		<ModalHeader toggle={toggle}>
			{title}
		</ModalHeader>

		<ModalBody>
			{children}
		</ModalBody>
	</Modal>
)


export const PopPDF = (props) => {
	const {url, modal_title, children, is_open, toggle, external} = props;
	return (
		<Fragment>
			<CustomModal is_open={is_open} toggle={toggle} style={{"height": '100%'}} title={modal_title}>
				<PDFViewWrapper src={url} style={{"height": '70vh'}} external={external} />
				{children}
			</CustomModal>
		</Fragment>
	);
}

export default CustomModal;