import {NotificationManager} from "components/common/react-notifications";


export function toastError (text, timeout = 3000) {
	NotificationManager.error(text, "Error", timeout, null, null, 'custom-notf');
}

export function toastSuccess (text, timeout = 700) {
	NotificationManager.success(text, "Success", timeout, null, null, 'custom-notf');
}
