import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import getClassNames from 'utils/getClassNames';

const styles = () => ({
	"horizontalCenter": {
		'justifyContent': 'center',
		'textAlign': 'center',
	}
});

function Col (props) {
	let {classes, horizontalCenter, className, ...restProps} = props;
	const classNames = getClassNames(className, classes, {horizontalCenter});

	return <Grid className={classNames} item {...restProps} />
}

export default withStyles(styles)(Col);
