export default function getValue (field, value) {
	if (value === undefined || value === null) {
		if (field.defaultValue) value = field.defaultValue;
		else if (field.type === 'multipleForm') value = [];
		else value = '';
	}

	if (['switch', 'checkbox'].includes(field.type)) {
		if (value === 'false') value = false;
		else if (value === 'true') value = true;
		else value = Boolean(value);
	}

	if (!value && field.type === 'json') {
		value = {};
	}

	return {value, "valid": true};
}
