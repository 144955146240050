export default function mapSelectOption (val, options, case_senstive = true) {
	if (!val && val !== false) {
		return '';
	};

	try {
		if (options && typeof options[0] === 'object') {
			if ("title" in options[0] && "value" in options[0]) {
				const option = options.find((o) => compare(o.value, val, case_senstive));
				return option ? option.title : '';
			} else {
				const option = options.find((o) => compare(o[1], val, case_senstive));
				return option ? option[0] : '';
			}
		}
		else {
			return val;
		}
	} catch(e) {
		console.error(e);
		return '';
	}
}

const compare = (a, b, case_senstive) => {
	a = a ? a.toString() : '';
	b = b ? b.toString() : '';
	if (!case_senstive) {
		a = a.toLowerCase();
		b = b.toLowerCase();
	}

	return a === b;
}
