import {
	CardBody,
	Row,
} from "reactstrap";
import {API_HOST_ROOT} from 'config/api-config';
import {Colxx} from "components/common/CustomBootstrap";
import Card from "components/Card";


const Container = ({children, ...props}) => (
	<Row style={{"textAlign": 'center'}}>
		<Colxx className="m-auto" xxs="12" xl="10" xxl="7" {...props}>
			{ children }
		</Colxx>
	</Row>
);

export default function PDFViewWrapper ({src, external, height, width, ...props}) {
	let full_url = src?.includes(API_HOST_ROOT) ? src : API_HOST_ROOT + src;
	if (external) {
		full_url = src;
	}
	if (!full_url) {
		return (
			<Container>
				<Card hideBorder>
					<CardBody>
						<h6>PDF has not yet been generated, please try again later.</h6>
					</CardBody>
				</Card>
			</Container>
		);
	}

	return (
		<iframe src={full_url} width={width} style={{height}} title="Deal PDF" {...props} />
	);
}

PDFViewWrapper.defaultProps = {
	"height":'75vh',
	"width":'100%'
}