import {toastError} from 'utils/toast';
import formatName from 'utils/formatFieldName';
import ROUTES from 'config/routes';
import {jwtLogout} from 'helpers/jwt_helpers';


function formatUnprocessableEntityMsg (data) {
	let errs = [];
	const errorDict = data.errors ? data.errors : data;
	for (let f in errorDict) {
		if (f !== "base") {
			errs.push(`${formatName(f)} ${errorDict[f].join(', ')}`);
		}
		else {
			errs.push(errorDict[f].join(', '));
		}
	};
	return `${errs.join('. ')}.`;
}

export default function handleError (result) {
	const {response, config} = result;

	if (response?.status === 401 && !config?.noRedirect) {
		jwtLogout();
		window.location.replace(ROUTES.login);
	}
  
	if (config && !config.noToast) {
		let text;
		if (!response) {
			text = 'No Response';
		}
		else if (response.status.toString().startsWith('4')) {
			try {
				text = formatUnprocessableEntityMsg(response.data);
			}
			catch (err) {
				console.error('formatting error', err);
				text = response.statusText;
			}
		}
		else {
			text = response.statusText;
		}
		toastError(text);
	}

	return Promise.reject(result);
}
