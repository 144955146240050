export const dtFormats = {
	"display": {
		'datetime': 'YYYY-MM-DD HH:mm',
		'time': "HH:mm:ss",
		'date': "YYYY-MM-DD"
	},
	"data": {
		'datetime': 'YYYY-MM-DDTHH:mm:ssZ',
		'time': 'HH:mm:ssZ',
		'date': 'YYYY-MM-DD',
	}
};
