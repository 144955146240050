
import {
	ApolloClient,
	InMemoryCache, split
} from "@apollo/client";
import {setContext} from '@apollo/client/link/context';
import {WebSocketLink} from 'apollo-link-ws';
import {createUploadLink} from "apollo-upload-client";
import {getMainDefinition} from 'apollo-utilities';
import {hasura_host, protocol} from "config/api-config";
import {getJWT} from "helpers/jwt_helpers";
import {SubscriptionClient} from "subscriptions-transport-ws";


const httpLink = new createUploadLink({"uri": `${protocol.http}://${hasura_host}`});

const wsClient: SubscriptionClient = new SubscriptionClient(`${protocol.ws}://${hasura_host}`, {
	"reconnect": true,
	"lazy": true,
	"timeout": 90000,
	"connectionParams": async () => ({
		"headers": {
			"authorization": getJWT(),
		}
	}),
})

const wsLink: WebSocketLink = new WebSocketLink(wsClient)

const link = split(
	// split based on operation type
	({query}) => {
		const {kind, operation} = getMainDefinition(query);
		return kind === 'OperationDefinition' && operation === 'subscription';
	},
	wsLink,
	httpLink,
);

const authLink = setContext((op, {headers}) => {
	// Retrieve the authorization token from local storage.
	const token = getJWT();
	const customHeaders = token ? {"authorization": token} : {};

	// return the headers to the context so httpLink can read them
	return {
		"headers": {
			...headers,
			...customHeaders,
		}
	}
})


const defaultOptions = {
	"watchQuery": {
		"fetchPolicy": 'cache-and-network',
		"errorPolicy": 'ignore',
	},
	"query": {
		"fetchPolicy": 'network-only',
		"errorPolicy": 'all',
	},
	"mutate": {
		"errorPolicy": 'all',
	},
};

export default function getApolloClient () {
	return new ApolloClient({
		"link": authLink.concat(link),
		"cache": new InMemoryCache(),
		defaultOptions
	});
}