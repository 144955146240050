export default function validateNumber (value, options) {
	const result = {"valid": true, "msg": ''};
	const {type} = options;

	if (type === 'integer') {
		if (parseInt(value) !== parseFloat(value)) {
			result.valid = false;
			result.msg = 'Please enter an integer value';
		}
	}

	const max = parseFloat(options.max);
	const maxExists = Boolean(max) || max === 0;
	if (maxExists && value > max) {
		result.valid = false;
		result.msg = `Max value is ${max}`;
	}

	const min = parseFloat(options.min);
	const minExists = Boolean(min) || min === 0;
	if (minExists && value < min) {
		result.valid = false;
		result.msg = `Min value is ${min}`;
	}

	return result;
}