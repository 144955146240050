import {defaultCountry, defaultLang} from 'config/defaultValues'
import {
	CHANGE_LOCALE,
	SET_AUTH_METHOD,
	SET_TABLE_USER_PREF,
} from '../actions';

export const default_state = {
	"locale": defaultLang,
	"lang": defaultLang,
	"localeCountry": defaultCountry,
	"auth_method": '',
	"user_tables_prefrences": {},
};


// eslint-disable-next-line default-param-last
export default function  reducer (state = default_state, action) {
	if (action.type === CHANGE_LOCALE) {
		return {...state, "locale": action.locale, "lang": action.lang, "localeCountry": action.localeCountry};
	}
	else if (action.type === SET_AUTH_METHOD) {
		return {...state, "auth_method": action.auth_method};
	}
	else if (action.type === SET_TABLE_USER_PREF) {
		const {table_name, table_user_pref} = action;
		const user_tables_prefrences = {...state.user_tables_prefrences};
		user_tables_prefrences[table_name] = table_user_pref;
		return {...state, user_tables_prefrences};
	}

	return state;
};
