import {Component, useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import CheckboxMUI from '@material-ui/core/Checkbox';


const styles = (theme) => ({
	"label": {
		"color": `${theme.palette.text.primary}!important`
	},
	"disabled_and_checked": {
		"color": `${theme.palette.primary.main} !important`,
	}
});

const useStyles = makeStyles(styles);

class CheckboxOld extends Component {
  onChange = (e) => {
  	const value = e.target.checked;
  	this.props.onChange(this.props.name, value, true, '');
  }

  render () {
  	// eslint-disable-next-line no-unused-vars
  	const {classes, onChange, helperText, value, inputProps, InputProps, disabled, labelPlacement, viewMode, ui_options, ...restProps} = this.props;
  	const label = (ui_options && ui_options.htmlLabel) ? <span dangerouslySetInnerHTML={{"__html":this.props.label}} ></span> :  this.props.label;
  	return (
  		<FormControl {...restProps}>
  			<FormControlLabel
  				control={
  					<CheckboxMUI
  						checked={Boolean(value)}
  						onChange={this.onChange}
  						color='primary'
  						disabled={Boolean(disabled) || viewMode}
  						inputProps={inputProps}
  						classes={{
  							"disabled": (viewMode && Boolean(value)) ? classes.disabled_and_checked : '',
  						}}
  					/>
  				}
  				label={label}
  				classes={{
  					"disabled": viewMode ? classes.label : '',
  				}}
  				labelPlacement={labelPlacement ? labelPlacement : "start"}
  			/>
  			{helperText && <FormHelperText>{ helperText }</FormHelperText>}
  		</FormControl>
  	)
  }
}

export default withStyles(styles)(CheckboxOld);


export const Checkbox = (props)=>{
	const classes = useStyles();
	// eslint-disable-next-line no-unused-vars
	const {onChange, helperText, value, inputProps, InputProps, disabled, labelPlacement, viewMode, ui_options, ...restProps} = props;
	const label = (ui_options && ui_options.htmlLabel) ? <span dangerouslySetInnerHTML={{"__html":props.label}} ></span> :  props.label;
	const [checked, setChecked] = useState(value);

	useEffect(()=>{
		setChecked(value);
	}, [value]);

	const onClick = (_event) => {
		const value = !checked;
		props.onChange(props.name, value, true, '');
	}
  
	return (
		<FormControl {...restProps}>
			<FormControlLabel
				control={
					<CheckboxMUI
						checked={Boolean(value)}
						onClick={onClick}
						color='primary'
						disabled={Boolean(disabled) || viewMode}
						inputProps={inputProps}
						classes={{
							"disabled": (viewMode && Boolean(value)) ? classes.disabled_and_checked : '',
						}}
					/>
				}
				label={label}
				classes={{
					"disabled": viewMode ? classes.label : '',
				}}
				labelPlacement={labelPlacement ? labelPlacement : "start"}
			/>
			{helperText && <FormHelperText>{ helperText }</FormHelperText>}
		</FormControl>
	)

}