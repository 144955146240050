import {createContext} from "react";
import {_LTracker} from 'loggly-jslogger';

const LoggingContext = createContext();


const trackingLogs = ['production'].includes(process.env.REACT_APP_ENV) ? _LTracker : [];
trackingLogs.push({
	'logglyKey': process.env["REACT_APP_LOGGLY_TOKEN"],
	'sendConsoleErrors' : false,
	'tag' : 'treyd-platform-logging'
});

const LoggingProvider = ({children}) => (
	<LoggingContext.Provider value={trackingLogs}>
		{children}
	</LoggingContext.Provider>
);

export {
	LoggingContext,
	LoggingProvider
}