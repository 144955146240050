import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from "redux-saga";
import reducers from './reducers';
import sagas from "./sagas";
import thunkMiddleware from 'redux-thunk'
import {persistStore, persistReducer, createMigrate} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {default_state as default_settings} from './settings/reducer';
import {merge} from 'lodash';
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, thunkMiddleware];

const persistMigrations = {
	"0": (state) => merge(state, {"settings": default_settings}),
}

  
const persistConfig = {
	"version": 1,
	"key": 'root',
	storage,
	"whitelist": ['deals', 'settings', 'notifications'],
	"migrate": createMigrate(persistMigrations, {"debug": false}),

};
const persistedReducer = persistReducer(persistConfig, reducers);


export function configureStore (initialState) {
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	const store = createStore(
		persistedReducer,
		initialState,
		composeEnhancers(applyMiddleware(...middlewares))
	);

	sagaMiddleware.run(sagas);

	if (module.hot) {
		module.hot.accept('./reducers', () => {
			const nextRootReducer = require('./reducers');
			store.replaceReducer(nextRootReducer);
		});
	}

	const persistor = persistStore(store)
	return {store, persistor};
}
