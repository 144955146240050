import request from './requestWrapper';


export default function get (url, options = {}) {
	const {id, params, noToast, ...rest_options} = options;

	if (id) {
		url = `${url}/${id}`;
	}
 
	return request({
		"method": 'GET',
		url,
		params,
		noToast,
		...rest_options
	});
}

