import validate from '../Form/lib/validate';
import TextField from '@material-ui/core/TextField';
import {IconButton, InputAdornment} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import {useToggle} from 'utils/generalHooks';

export default function PasswordInput (props) {
	const [showPassword, toggleShowPassword] = useToggle(false);

	const onChange = (e) => {
		const {name, value} = e.target;
		const {valid, msg} = validate(value, props);
		props.onChange(name, value, valid, msg);
	}

	const {label, value, ...restProps} = props;
	return (
		<TextField
			{...restProps}
			InputLabelProps={{
				"shrink": true,
			}}
			label={label}
			value={value}
			onChange={onChange}
			type={showPassword ? 'text' : 'password'}
			InputProps={{
				"endAdornment": (
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={toggleShowPassword}
						>
							{showPassword ? <Visibility /> : <VisibilityOff />}
						</IconButton>
					</InputAdornment>
				)
			}}
		/>
	)
}