import {withStyles} from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';


const styles = (theme) => ({
	"root": {
	},
	"icon": {
		"fontSize": 20,
	},
	"iconVariant": {
		"opacity": 0.9,
		"marginRight": theme.spacing(1),
	},
});


function SaveButton (props) {
	const {classes, onClick, disabled} = props;
	return (
		<IconButton
			className={classes.root}
			onClick={onClick}
			color="primary"
			disabled={disabled}
		>
			<SaveIcon className={classes.icon} />
		</IconButton>
	)
}

export default withStyles(styles)(SaveButton);
