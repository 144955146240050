
export default class Response {
    data: any;
    errors: any;
    pagination: Pagination;

    constructor (c: any) {
    	Object.assign(this, c);
    	this.data = c.data?.payload || c.data;
    	this.errors = c.data?.errors;
    	this.warnings = c.data?.errors;
    	this.pagination = c.data?.pagination;
    }
}


export interface Pagination {
    current: number;
    previous: number;
    next: number | null;
    limit: number;
    total_pages: number;
    total_count: number
}