export const PLATFORM_VERSION_KEY = 'platform_version_id';
export const MAX_RECENT_DEALS = 3;
export const AUTH_TOKEN_KEY = '_authKey';
export const AUTH_LAST_REFRESHED_AT = '_authLastRefreshedAt';
export const REFRESH_TOKEN_KEY = '_refreshKey';
export const REFRESH_ACCESS_TOKEN_FREQ = 1000 * 60 * 1; // 1 minutes;
export const MAX_TIME_OF_INACTIVITY = 60 * 5; // 5 minutes;
export const CURRENT_REFRESH_TOKEN_KEY = '_currentRefreshKey';
export const POST_LOGIN_REDIRECT = 'afterLoginURL';
export const SCHEMA_EDITOR_FIELDS = "SCHEMA_EDITOR_FIELDS";
