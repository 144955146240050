import {Component, memo} from 'react';
import {withStyles} from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import InputAdornment from '@material-ui/core/InputAdornment';
import SectionDivider from '../SectionDivider/SectionDivider';
import ComboBox from '../ComboBox/ComboBox';
import DateTimeField from '../DateTimeField/DateTimeField';
import TextInput from '../TextInput/TextInput';
import PasswordInput from '../PasswordInput';
import NumberField from '../NumberField/NumberField';
import ViewField from '../ViewField/ViewField';
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import MultipleForm from '../MultipleForm/MultipleForm';
import FileField from '../FileField/FileField';
import SelectField from '../SelectField/SelectField';
import RadioSelect from '../RadioSelect/RadioSelect';
import JSONField from '../JSONField/JSONField';
import Checkbox from '../Checkbox/Checkbox';
import Switch from '../Switch/Switch';
import formatName from 'utils/formatFieldName';
import FormContext from '../FormContext';
import getValue from '../Form/lib/getValue';
import {Row, Col} from '../Grid';
import isEqual from "react-fast-compare";


const allowed_tags = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'li', 'ul'];

const styles = () => ({
	"input": {
		"width": '15em'
	},
	"gridCont": {
		"marginBottom": '0.5rem',
	}
});

function getStyles (fieldStyle, contextFieldStyle, width) {
	// Use field.style overrides context.fieldstyle
	// TO DO: Deep merge these 2 so that xs, sm ... are also merged.
	// (Also think whether it should be merged or override)
	const style = {...contextFieldStyle, ...fieldStyle};
	if (style) {
		// eslint-disable-next-line no-unused-vars
		const {xs, sm, md, lg, xl, ...defaultStyle} = style;

		const widthValues = ["xs", "sm", "md", "lg", "xl"];
		let responsiveStyle;
		for (let i = 0; i < widthValues.length; i++) {
			const s = widthValues[i];
			if (style[s]) {
				responsiveStyle = style[s];
			}
			if (s === width) {
				break;
			}
		}
		return responsiveStyle ? {...defaultStyle, ...responsiveStyle} : defaultStyle;
	} else {
		return undefined;
	}
}

class FormFields extends Component {
	componentDidMount () {
		if (this.props.updateStackLayout) {
			this.props.updateStackLayout();
		}
	}

	render () {

		const {schema, data, onChange, classes, editMode, width, hideEmptyField, submit_clicked} = this.props;
		const contextFieldStyle = this.context.fieldStyle;

		return (
			<Row spacing={3} className={classes.gridCont}>
				{
					schema.map((field, idx) => {
						const compKey = idx + field.key;

						return (
							<FieldWrapper
								key={compKey}
								field={field}
								dataField={data[field.key]}
								className={classes.input}
								width={width}
								editMode={editMode}
								onChange={onChange}
								hideEmptyField={hideEmptyField}
								contextFieldStyle={contextFieldStyle}
								submit_clicked={submit_clicked}
							/>
						)
					})
				}
			</Row>
		)
	}
}

const Field = ({field, dataField, className, width, editMode, onChange, hideEmptyField, contextFieldStyle, submit_clicked}) => {
	const label = field.label ? field.label : formatName(field.key);
	dataField = dataField || getValue(field);

	const error = field.error || !dataField.valid;
	const helperText = field.helperText && error ? field.helperText : dataField.msg;


	const commonProps = {
		"name": field.key,
		"value": dataField.value,
		error,
		helperText,
		label,
		"required": field.required,
		"margin": 'normal',
		onChange,
		"inputProps": {
			"data-test": field.key,
			...field.inputProps
		},
		"InputProps": {
			"startAdornment": field.start ? <InputAdornment position="start">{field.start}</InputAdornment> : undefined,
			"endAdornment": field.end ? <InputAdornment position="end" variant="filled">{field.end}</InputAdornment> : undefined,
			...field.InputProps,
		},
		"style": getStyles(field.style, contextFieldStyle, width),
		className,
		"placeholder": field.placeholder,
	};

	let fieldItem;

	if (field.hidden || field.type === 'hidden') {
		return null;
	}
	else if (field.type === 'section') {
		fieldItem = <SectionDivider title={field.key} />
	}
	else if (field.type === 'html') {
		const html_props = {
			"className": field.className,
			"style": field.style,
		};

		if (!allowed_tags.includes(field.tag)) {
			return null;
		}
		else if (field.tag === 'ul') {
			fieldItem = (
				<ul {...html_props}>
					{field.text.map((t, idx) => <li key={idx}>{t}</li>)}
				</ul>
			);
		}
		else {
			fieldItem = <field.tag {...html_props}>{field.text}</field.tag>;
		}
	}
	else if (field.type === 'switch') {
		const viewMode = field.readonly || !editMode;
		fieldItem = <Switch
			viewMode={viewMode}
			disabled={field.disabled}
			{...commonProps}
		/>
	}
	else if (field.type === 'checkbox') {
		const viewMode = field.readonly || !editMode;
		fieldItem = <Checkbox
			viewMode={viewMode}
			labelPlacement={field.labelPlacement}
			disabled={field.disabled}
			{...commonProps}
		/>
	}
	else if (field.type === 'component' && field.render && typeof field.render === 'function') {
		const viewMode = field.readonly || !editMode;
		fieldItem = field?.render({viewMode, ...field, ...commonProps});
	}
	else if (field.type === 'json') {
		const viewMode = !editMode || field.readonly || field.disabled
		fieldItem = <JSONField
			viewMode={viewMode}
			{...commonProps}
		/>
	}
	else if (field.type === 'multipleForm') {
		const viewMode = field.readonly || !editMode || field.disabled;

		fieldItem = <MultipleForm
			editMode={!viewMode}
			submit_clicked={submit_clicked}
			schema={field.schema}
			show_seperator={field.show_seperator}
			can_add={field.can_add}
			can_remove={field.can_remove}
			{...commonProps}
		/>
	}
	// View only field
	else if (!editMode || field.readonly || field.disabled) {
		const {"value": val} = commonProps;
		const emptyValue = (!val && val !== 0) || (field.multiple && val && val.length === 0);
		if (hideEmptyField && emptyValue) {
			return null;
		}

		fieldItem = <ViewField
			type={field.type}
			options={field.options}
			noForm={field.noForm}
			{...commonProps}
			margin="dense"
			formatValue={field.formatValue}
			metadata={field.metadata}
			datatype={field.datatype}
			multiple={field.multiple}
		/>
	}
	else if (['text', 'string'].includes(field.type)) {
		fieldItem = (
			<TextInput
				type='text'
				multiline={field.multiline}
				autoComplete={field.autoComplete}
				validate={field.validate}
				metadata={field.metadata}
				{...commonProps}
			/>
		);
	}
	else if (field.type === 'email') {
		fieldItem = (
			<TextInput
				type='email'
				autoComplete={field.autoComplete}
				{...commonProps}
			/>
		);
	}
	else if (field.type === 'password') {
		fieldItem = (
			<PasswordInput
				{...commonProps}
			/>
		);
	}
	else if (['number', 'integer', 'float', 'decimal'].includes(field.type)) {
		fieldItem = (
			<NumberField
				type={field.type}
				max={field.max}
				min={field.min}
				{...commonProps}
			/>
		);
	}
	else if (field.type === 'list') {
		fieldItem = <ComboBox
			blueprint_id={field.blueprint_id}
			options={field.options}
			noSearch={field.noSearch}
			backendSearch={field.backendSearch}
			backendSearchProps={field.backendSearchProps}
			noAddButton={field.noAddButton}
			addBtnRender={field.addBtnRender}
			blueprintURL={field.blueprintURL}
			{...commonProps}
		/>
	}
	else if (field.type === 'select') {
		fieldItem = <SelectField
			options={field.options}
			noEmptyOption={field.noEmptyOption}
			{...commonProps}
		/>
	}
	else if (field.type === 'radio') {
		fieldItem = <RadioSelect
			options={field.options}
			noEmptyOption={field.noEmptyOption}
			labelPlacement={field.labelPlacement}
			type={field.value_type}
			{...commonProps}
		/>
	}
	else if (['date', 'datetime', 'time'].includes(field.type)) {
		fieldItem = <DateTimeField
			type={field.type}
			disablePast={field.disablePast}
			minDate={field.minDate}
			{...commonProps}
		/>
	}
	else if (field.type === 'multipleSelect') {
		fieldItem = <MultipleSelect
			options={field.options}
			values={dataField.value ? dataField.value : []}
			{...commonProps}
		/>
	}
	else if (field.type === 'file') {
		fieldItem = <FileField
			metadata={field.metadata}
			template={field.template}
			full_url={field.full_url}
			{...commonProps}
		/>
	}
	else if (!fieldItem) {
		return null;
	}

	const colProps = field.colProps ? field.colProps : {"xs": 12};
	const {"style": colPropsStyle, ...restColProps} = colProps;

	return (
		<Col
			{...restColProps}
			style={{
				"paddingBottom": '0px',
				"paddingTop": '0px',
				...colPropsStyle
			}}
		>
			{ fieldItem}
		</Col>
	);
}

function areEqual (prevProps, nextProps) {
	return isEqual(prevProps, nextProps);
}

const FieldWrapper = memo(Field, areEqual);

FormFields.contextType = FormContext;

export default withStyles(styles)(withWidth()(FormFields));
