/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 0; // ie: always show
export const menuHiddenBreakpoint = 768;
export const defaultLang = "en";
export const defaultCountry = "SE";
// const localeOptions = [
//   { id: "en", name: "English", direction: "ltr" },
//   // { id: "se", name: "Svenska", direction: "ltr" },
// ];


/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const themeColorStorageKey = "__theme_color"
export const isMultiColorActive = false;
export const isDarkSwitchActive = false;
export const defaultColor = "treyd";
export const defaultDirection = "ltr";