/* eslint-disable prefer-destructuring */
import qs from 'query-string';

export default function endWithJson (url) {
	const parsedUrl = qs.parseUrl(url);
	if (!parsedUrl.url.endsWith(".json")) {
		parsedUrl.url = `${parsedUrl.url}.json`;
	};

	url = parsedUrl.url;
	const query = qs.stringify(parsedUrl.query);
	if (query) {
		url += `?${query}`;
	};
	return url;
}