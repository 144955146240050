import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';

import {withStyles} from '@material-ui/core/styles';

const styles = () => ({
	"title": {
	},
	"cont": {
		// marginBottom: '0.5rem',
		"marginTop": '1rem'}
});

function SectionDivider ({classes, title, style}) {
	return (
		<div className={classes.cont} style={style}>
			<Typography variant="h6" className={classes.title}>
				{title}
			</Typography>
			{/* <Divider /> */}
		</div>
	)
}

export default withStyles(styles)(SectionDivider);