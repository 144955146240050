export default function search (options, term, accessor = 'title', limit = 200) {
	if(!term) {
		return options;
	}

	term = term.toLowerCase().trim();
	return options
		.filter((o) => (o[accessor] ? o[accessor].toLowerCase().includes(term) : false))
		.slice(0, limit)
		.sort((opt1, opt2) => {

			const idx1 = opt1[accessor].toLowerCase().indexOf(term);
			const idx2 = opt2[accessor].toLowerCase().indexOf(term);
			return idx1 - idx2;
		});
}