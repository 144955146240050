export function stopWarningAsErrors () {
	const errorsToWarn = [
		"Warning:",
	];
	const oldConsError = console.error;
	console.error = function (...args) {
		let toWarn = false;

		if (typeof args[0] === 'string') {
			errorsToWarn.forEach((_s) => {
				if (args[0].startsWith(_s)) {
					toWarn = true;
				}
			});
		}

		!toWarn && oldConsError(...args);
	};
}
