import {makeStyles} from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Tooltip from '@material-ui/core/Tooltip';


const convert_string_to_type = (value, type) => {
	switch (type) {
	case 'boolean':
		return value === 'false' ? false : true;
	case 'number':
		return Number(value);
	default:
		return value;
	}
};

const useStyles = makeStyles(() => ({
	"radioGroupEnd": {
		"width": '100%',
		"display": 'flex',
		"flexDirection":'row',
		'&>*': {
			"marginRight": '50px',
			"marginTop": '0.5rem',
			"marginBottom": 0,
		}
	},
	"radioGroupStart": {
		"width": '100%',
		"display": 'flex',
		"flexDirection":'row',
		'&>*': {
			"marginLeft": '50px',
			"marginTop": '0.5rem',
			"marginBottom": 0,
		}
	},
	"label": {
		"fontSize": '12px',
		"marginBottom": 0,
	},
}));


export default function RadioSelect (props) {
	const classes = useStyles();
	const {options, label, name, margin, error, helperText, required, style, className, tooltip, enumTips, value, labelPlacement, type} = props;
	// const options = enumOptions.map(opt => ({ title: opt.label, value: opt.value }));

	// This is used so that it stays a controlled component
	// const value = props.value === undefined ? '' : props.value;
	return (
		<FormControl
			required={required}
			margin={margin}
			style={style}
			className={className}
		>
			{
				tooltip && label && <Tooltip title={tooltip}><FormLabel className={classes.label}>{label}</FormLabel ></Tooltip>
			}
			{
				!tooltip && label && <FormLabel className={classes.label}>{label}</FormLabel >
			}
			<RadioGroup
				className={labelPlacement === "end" ?  classes.radioGroupEnd : classes.radioGroupStart}
				aria-label={label}
				name={name}
				value={value.toString()}
				onChange={(e) => {
					const {name, value} = e.target;
					const newValue = convert_string_to_type(value, type ? type : 'boolean')
					props.onChange(name, newValue, true, '');
				}}
			>
				{
					enumTips &&
          options.map((opt, idx) => <Tooltip title={enumTips[idx]} key={`${idx}_${opt.value}`}>
          		<FormControlLabel
          			labelPlacement={labelPlacement || "start"}
          			value={opt.value.toString()}
          			control={<Radio color='primary' />}
          			label={opt.title}
          		/>
          	</Tooltip>
          )
				}

				{!enumTips &&
          options.map((opt, idx) => <FormControlLabel
          		labelPlacement={labelPlacement || "start"}
          		key={`${idx}_${opt.value}`}
          		value={opt.value.toString()}
          		control={<Radio color='primary' />}
          		label={opt.title}
          	/>
          )
				}
			</RadioGroup>

			{error && helperText &&
        <FormHelperText error={true}>{helperText}</FormHelperText>
			}
		</FormControl>
	)
}


