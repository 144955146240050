import {gql, useQuery} from "@apollo/client";
import {defaultFeatureFlags} from "config/feature_flags";
import {Store} from "interfaces/Store";
import {createContext, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";

export type FeatureFlags = typeof defaultFeatureFlags | Record<string, boolean>

const FeatureFlagContext = createContext<FeatureFlags>(defaultFeatureFlags);


const userFeatureFlags = gql`
query getCompanyFeatureFlags($company_id: bigint!) {
  orders_orders_app_companyfeatureflag(where: {company_id: {_eq: $company_id}}) {
    id
    enabled
    name
  }
}
`;

interface FeatureFlagProviderProps {
	initialState?: Record<string, boolean>;
	children: React.ReactNode;
}
const FeatureFlagProvider = ({children, initialState}: FeatureFlagProviderProps) => {
	const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(initialState || defaultFeatureFlags);
	const current_user = useSelector((store: Store) => store.credentials);
	const {data} = useQuery(userFeatureFlags, {variables: {company_id: current_user?.company_id || current_user?.company?.id}});

	const companyFeatureFlags = useMemo(() => data?.orders_orders_app_companyfeatureflag.reduce((acc: FeatureFlags, curr: {name: keyof FeatureFlags; enabled: boolean;}) => {
		acc[curr?.name] = curr.enabled;
		return acc;
	}, {}), [data]);
	
	useEffect(() => {
		//some logic of merging the default flags with the user flags
		setFeatureFlags({...defaultFeatureFlags, ...companyFeatureFlags})
	}, [companyFeatureFlags])
	
	return (
		<FeatureFlagContext.Provider value={featureFlags}>
			{children}
		</FeatureFlagContext.Provider>
	)
};


export {
	FeatureFlagContext,
	userFeatureFlags,
	FeatureFlagProvider,
};
