import {Card, makeStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as Sentry from "@sentry/react";
import {Integrations} from '@sentry/tracing';
import {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";

const envs = process.env;

const useStyles = makeStyles((theme) => ({
	"container": {
		"height": "100%",
		"display": 'flex',
		"flexDirection": 'column',
		"justifyContent": 'center',
		"alignItems": 'center',
	},
	"card": {
		"padding": '5rem',
		"display": "flex",
		"flexDirection": "column",
		"alignItems": "center",
		"justifyContent": "center",
		"width": "fit-content",
		"minWidth": "50%",
		"marginTop": theme.spacing(2),
	}
}));


export default function ErrorsBoundary ({children}) {
	const classes = useStyles();
	const [eventId, setEventId] = useState(null);
	const user = useSelector((state) => state.credentials);

	useEffect(() => {
		Sentry.init({
			"dsn": envs.REACT_APP_SENTRY,
			"integrations": [new Integrations.BrowserTracing()],
			"tracesSampleRate": 0.1,
			"environment": envs.REACT_APP_ENV,
			"send_client_reports": true,
			"ignoreErrors": [
				'ChunkLoadError',
			],
			"initialScope": {
				"user": {
					"user_id": user?.id,
					"id": user?.id,
					"user_company_id": user?.company_id,
				}
			}
		});
	}, [user.id, user.company_id]);

	const captureErrorWithScope = useCallback((error, componentStack) => {
		let captured_event_id = eventId;
		Sentry.withScope((scope) => {
			scope.setExtras(componentStack);
			scope.setExtra('user.name', user.name);
			scope.setExtra('user.email', user.email);
			scope.setExtra('user.user_id', user.id);
			scope.setExtra('user.id', user.id);
			scope.setExtra('user.user_company_id', user.company_id);
      
			if (!captured_event_id) {
				captured_event_id = Sentry.captureException(error);
				setEventId(eventId);
			}
		});
		return captured_event_id;
	}, [eventId, user.company_id, user.email, user.id, user.name]);

	const showReportDialog = (eventId) => {
		Sentry.showReportDialog({
			"environment": envs.REACT_APP_ENV,
			eventId,
			"user": {
				"name": user.name,
				"email": user.email,
				"user_id": user.id,
				"company_id": user.company_id,
			}
		});
	}

	return (
		<Sentry.ErrorBoundary
			fallback={({error, componentStack}) => {
				if (error.message.includes('Chunk') || error.message.includes('chunk')) {
					window.location.reload();
					return null;
				}
				const eventId = captureErrorWithScope(error, componentStack);
				//if error related to chunk loading, reload the page

				return (
					<main className={classes.container}>
						<Card className={classes.card}>
							<Typography variant='h3'>
                An error has occurred
							</Typography>

							<Typography variant='subtitle2' className='mt-3 mb-3'>
                Our team has been notified. If you'd like to help, tell us what happened.
							</Typography>

							<Button className='mt-4' onClick={()=>showReportDialog(eventId)} variant='contained' color='primary'>Report feedback</Button>
						</Card>
					</main>
				)
			}}
		>
			{children}
		</Sentry.ErrorBoundary>
	)
}
