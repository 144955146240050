import {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import validate from '../Form/lib/validate';


export default class NumberField extends Component {
  onChange = (e) => {
  	const {name, value} = e.target;
  	const {valid, msg} = validate(value, this.props);
  	const formatted_value = Number(value) || value;
  	this.props.onChange(name, formatted_value, valid, msg);
  }

  render () {
  	// eslint-disable-next-line no-unused-vars
  	let {onChange, type, label, value, ...restProps} = this.props;
  	const step = type === 'integer' ? '1' : 'any';

  	return (
  		<TextField
  			type="number"
  			step={step}
  			onChange={this.onChange}
  			label={label}
  			value={value}
  			{...restProps}
  		/>
  	)
  }
}
