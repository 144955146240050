function canPostField (field) {
	return !field.disabled && field.type !== "section" && !field.view_only;
}

function format_for_JSON (schema, form_data, dataKey) {
	const inner_data = {};
	schema.forEach((field) => {
		if (canPostField(field)) {
			inner_data[field.key] = form_data[field.key].value;
		}
	});

	if (dataKey) {
		return construct_object(dataKey, inner_data);
	}
	else {
		return inner_data;
	}
}

function construct_object (dataKey, inner_data) {
	const data = {};
	let last_data = data;
	const path_keys = dataKey.split(".");
	path_keys.forEach((dk, idx) => {
		last_data[dk] = idx === path_keys.length - 1 ? inner_data : {};
		last_data = last_data[dk];
	});
	return data;
}


function nest_key (parent, child) {
	const re = /[^[\]]+/g;
	child = child.match(re).map((s) => `[${s}]`).join("");
	return `${parent}${child}`;
}

function format_for_form (schema, form_data, dataKey) {
	const data = new FormData();

	schema
		.filter(canPostField)
		.forEach((field) => {
			let name = dataKey ? nest_key(dataKey, field.key) : field.key;
			let {value} = form_data[field.key];
			if (field.type === 'file') {
				if (value.file_data) {
					value = value.file_data;
				}
				else {
					return;
				}
			}
			else if (field.type === 'json') {
				value = JSON.stringify(value);
			}

			if (field.type === 'multipleSelect') {
				if (!value || value.length === 0) {
					return;
				}
				else {
					name = `${name}[]`;
					value.forEach((v) => data.append(name, v));
				}
			}
			else if (field.type === 'multipleForm') {
				if (!value || value.length === 0) {
					return;
				}
				else {
					value.forEach((nested_value) => {
						// TO DO: This only supported only one level of nested field and won't work on files
						field.schema.forEach((nested_field) => {
							data.append(`${name}[][${nested_field.key}]`, nested_value[nested_field.key]);
						})
					});
				}
			}
			else {
				data.append(name, value)
			}
		});
	return data;
}

export default function format_data_for_save (schema, data, enctype = "", dataKey = "") {
	return enctype === 'multipart/form-data'  ? format_for_form(schema, data, dataKey) : format_for_JSON(schema, data, dataKey);
}
