import {toastSuccess} from 'utils/toast';


export default function handleSuccess (response) {
	const {config} = response;
	if (!(config.noToast || config.noToastOnSuccess) && ['post', 'put', 'delete'].includes(response.config.method)) {
		toastSuccess('Data updated successfully');
	}
  
	return response;
}