import axios from './axiosInstance'
import general_axios from './general_axiosInstance'
import endWithJson from './endWithJson';
import Response from './Response';
import {API_HOST, LAGER_API_HOST} from 'config/api-config';
import {getJWT} from 'helpers/jwt_helpers';


export default async function requestWrapper ({no_auth, ext_host, ...options}) {
	options.headers = {
		...options.headers,
	};
	if (ext_host) {
		options.headers['Access-Control-Allow-Origin'] = '*';
		options.headers['Access-Control-Allow-Methods'] = '*';
		const res = await general_axios.request(options)
		return new Response(res)
	}

	const auth = getJWT();
	if (auth && !no_auth) {
		options.headers['Authorization'] = auth;
		options.headers['AUTHORIZATIONJWT'] = auth;
	}


	if (!options.baseURL) {
		if (!options.baseURL && options.url.includes('/lager/')) {
			options.baseURL = LAGER_API_HOST;
			options.url = options.url.replace('/lager/', '');
		} else {
			options.baseURL = API_HOST;
			options.url = endWithJson(options.url);
		}
	}
	const res = await axios.request(options);
	return new Response(res)
}