import {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import {Button, Tooltip} from '@material-ui/core';

const styles = (theme) => ({
	"icon": {
		"fontSize": 20,
		"color": theme.palette.error.main,
	},
	"iconVariant": {
		"opacity": 0.9,
		"marginRight": theme.spacing(1),
	},
	"fabButton": {
		"marginTop": '11px',
	},
	"button": {
		"width": 'auto',
		"minWidth": '125px',
		"borderRadius": 50,
		"marginLeft":'10px',
	},
	"iconButton": {
		"height": '0.5rem',
		"width": '0.5rem',
	}
});


const DeleteForeverIcon = (props)=>(props.deleteIcon ? <RemoveCircleOutlineOutlinedIcon {...props}/> : <CloseIcon {...props}/>)

class DeleteButton extends Component {
	static Button (props) {
		const {
			onClick,
			classes,
			fab,
			tooltip,
			deleteText,
			button,
		} = props;

		if (fab) {
			return (
				<Fab onClick={onClick} size="small" className={classes.fabButton} disabled={props.disabled}>
					<DeleteForeverIcon className={classes.icon} deleteIcon={props.deleteIcon}/>
				</Fab>
			);
		}

		else if (button) {
			return(<Button
				className={classes.button}
				data-test='form-submit'
				onClick={onClick}
				variant="contained"
				disabled={props.disabled}
			>
				{deleteText || "Delete"}
			</Button>);
		}

		return (
			<Tooltip title={tooltip ? tooltip : ''}>
				<IconButton className={classes.iconButton} color="inherit" onClick={onClick} disabled={props.disabled}>
					<DeleteForeverIcon className={classes.icon} deleteIcon={props.deleteIcon}/>
				</IconButton>
			</Tooltip>
		);
	}

	constructor () {
		super();
		this.state = {
			"loading": false,
		}
	}

  confirmDelete = () => {
  	const promise = this.props.onClick(this.props.value);
  	if (promise) {
  		this.setState({"loading": true});
      
  		// We don't change the loading after success because the button will be unmonted after delete
  		promise.catch(() => {
  			this.setState({"loading": false});
  		});
  	}
  }

  render () {
  	const {classes, title, body, tooltip, button, deleteText} = this.props;
  	const {loading} = this.state;
  	if (loading) {
  		return <CircularProgress variant="indeterminate" size={22} thickness={4} />
  	}
  	return (
  		<ConfirmDialog
  			title={title ? title : "Confirm Delete"}
  			body={body ? body : "All related data will be deleted. Are you sure you want to delete it."}
  			onConfirm={this.confirmDelete}
  			renderButton={(onClick) => <DeleteButton.Button
  					onClick={onClick}
  					classes={classes}
  					fab={this.props.fab}
  					tooltip={tooltip}
  					deleteIcon={this.props.deleteIcon}
  					deleteText={deleteText}
  					button={button}
  				/>
  			}
  		/>
  	)
  }
}

DeleteButton.propTypes = {
	"fab": PropTypes.bool,
}
export default withStyles(styles)(DeleteButton);
