import {Component} from 'react';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {DatePicker, DateTimePicker, TimePicker} from '@material-ui/pickers';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import validate from '../Form/lib/validate';
import {dtFormats} from 'config/formats';

const pickerMap = {
	'datetime': DateTimePicker,
	'time': TimePicker,
	'date': DatePicker
};

const styles = () => ({
	"root": {
		'& input': {
			"cursor": 'pointer',
		}
	},
});

class DateTimeField extends Component {
  onChange = (date) => {
  	const {name} = this.props;
  	const format = dtFormats.data[this.props.type]
  	const value = date ? date.format(format) : '';

  	let result = validate(value, this.props);

  	this.props.onChange(name, value, result.valid, result.msg);
  }

  onError = (error, value) => {
  	if (error) {
  		console.error('DateTimeField Error', error)
  		const {name} = this.props;
  		this.props.onChange(name, value, false, 'error');
  	}
  }

  render () {
  	// eslint-disable-next-line no-unused-vars
  	let {onChange, value, type, classes, className, ...restProps} = this.props;
  	if (!value) {
  		value = null;
  	}

  	const format = dtFormats.display[type];
  	const Picker = pickerMap[type];

  	return (
  		<MuiPickersUtilsProvider utils={MomentUtils}>
  			<Picker
  				{...restProps}
  				className={classNames(classes.root, className)}
  				autoOk
  				value={value}
  				// clearable
  				// showTodayButton
  				onError={this.onError}
  				format={format}
  				onChange={this.onChange}
  				InputLabelProps={{
  					"shrink": true,
  					"color": 'secondary',
  				}}
  			/>
  		</MuiPickersUtilsProvider>
  	)
  }
}

export default withStyles(styles)(DateTimeField);

