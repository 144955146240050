import {
	UPDATE_RECENT_DEALS,
	SET_RECENT_DEAL_OPEN,
	SET_ALL_DEAL_OPEN,
	SET_COMPANY_DEALS,
	SET_ACTIVE_DEAL_FORM_DATA,
	SET_CURRENT_DEAL,
	SET_CURRENT_DEAL_WARNINGS,
	SET_CURRENT_DEAL_ERRORS,
	SET_IS_DEAL_SUBMIT_CLICKED,
} from '../actions';
import {MAX_RECENT_DEALS} from 'config/constants'

const default_state = {
	"recent_ids": [],
	"recent_open": true,
	"all_open": true,
};

// eslint-disable-next-line default-param-last
export default function updateRecentDeals (state = default_state, action) {
	if (action.type === UPDATE_RECENT_DEALS) {
		const deal_id = parseInt(action.deal_id);
		if (!deal_id) {
			return state;
		}

		const recent_ids = state.recent_ids.filter((id) => id !== deal_id);
		if (recent_ids.length >= MAX_RECENT_DEALS) {
			recent_ids.pop();
		}
    
		recent_ids.unshift(deal_id);
		return {...state, recent_ids};
	}
	else if (action.type === SET_RECENT_DEAL_OPEN) {
		return {...state, "recent_open": action.flag};
	}
	else if (action.type === SET_ALL_DEAL_OPEN) {
		return {...state, "all_open": action.flag};
	}
	else if (action.type === SET_COMPANY_DEALS) {
		return {...state, "company_deals": action.deals}
	}
	else if (action.type === SET_CURRENT_DEAL) {
		return {...state, "current_deal": action.deal}
	}
	else if (action.type === SET_CURRENT_DEAL_WARNINGS) {
		return {...state, "current_deal_warnings": action.warnings}
	}
	else if (action.type === SET_CURRENT_DEAL_ERRORS) {
		return {...state, "current_deal_errors": action.errors}
	}
	else if (action.type === SET_IS_DEAL_SUBMIT_CLICKED) {
		return {...state, "is_current_deal_submit_clicked": action.is_current_deal_submit_clicked}
	}
	else if (action.type === SET_ACTIVE_DEAL_FORM_DATA) {
		const {formData, is_dirty, id, formatBeforeSubmit, submit_url, setExtraErrors} = action;
		return {...state, "active_deal_form": {...formData, is_dirty, formatBeforeSubmit, submit_url, setExtraErrors}, id}
	}
	return state;
};
