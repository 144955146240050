/* eslint-disable default-param-last */
import {
	SET_CREDENTIALS,
	SET_ERP,
	SET_CURRENT_COMPANY,
	SET_USER_COMPANIES,
} from '../actions';
import User from 'models/User';


export default function setCredential (state = {}, action) {
	if (action.type === SET_CREDENTIALS) {
		return new User(action.creds);
	}
	return state;
};

export const userCompanies = (state = [], action) => {
	if (action.type === SET_USER_COMPANIES && action.userCompanies) {
		return action.userCompanies;
	}
	return state;
};

export const currentCompany = (state = {}, action) => {
	if (action.type === SET_CURRENT_COMPANY && action.current_company) {
		return action.current_company
	}
	return state;
};

export const erpIntegration = (state = {}, action) => {
	if (action.type === SET_ERP) {
		return action.erp_integration
	}
	return state;
};

