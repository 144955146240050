import {
	SET_COUNTRIES,
	SET_USER_STATUSES,
	SET_DEAL_STATUSES,
	SET_CURRENCIES,
} from '../actions';

export const setCountries = (countries) => ({
	"type": SET_COUNTRIES,
	countries,
});


export const setCurrencies = (currencies) => ({
	"type": SET_CURRENCIES,
	currencies,
});

export const setUsersStatuses = (statuses) => ({
	"type": SET_USER_STATUSES,
	statuses,
});

export const setDealStatuses = (deal_statuses) => ({
	"type": SET_DEAL_STATUSES,
	deal_statuses,
});
