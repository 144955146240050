import {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import getTestSelector from 'utils/getTestSelector';
import validate from '../Form/lib/validate';
import classNames from 'classnames';


const ITEM_HEIGHT = 46;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	"PaperProps": {
		"style": {
			"maxHeight": ITEM_HEIGHT * 5 + ITEM_PADDING_TOP * 2,
		},
	},
};

const styles = (theme) => ({
	"formControl": {
		"minWidth": 120,
	},
	"chips": {
		"display": 'flex',
		"flexWrap": 'wrap',
	},
	"chip": {
		"margin": theme.spacing(1 / 4),
	},
});


class MultipleSelect extends Component {
  handleChangeMultiple = (event) => {
  	// let values = event.target.value;
  	// this.props.onChange(values);

  	const {name} = this.props;
  	let values = event.target.value;
  	const {valid, msg} = validate(values, this.props);
  	this.props.onChange(name, values, valid, msg);
  };

  handleCheckbox = (event) => {
  	event.stopPropagation();
  	event.preventDefault();
  }

  render () {
  	const { 
  		// eslint-disable-next-line no-unused-vars
  		options, values, classes, label, inputProps, InputProps, required, error, helperText, style, className, ...rest 
  	} = this.props;

  	const menuItemProps = {
  		"data-test": getTestSelector(inputProps, 'menu')
  	};

  	return (
  		<FormControl
  			required={required}
  			className={classNames(classes.formControl, className)}
  			margin="normal"
  			variant='outlined'
  			error={error}
  			style={style}
  			{...inputProps}
  			{...rest}
  		>
  			<InputLabel shrink>{label}</InputLabel>
  			<Select
  				multiple
  				value={values}
  				onChange={this.handleChangeMultiple}
  				renderValue={(selected) => (
  					<div className={classes.chips}>
  						{
  							selected.map((value, idx) => {
  								const selOpt = options.find((opt) => opt.value === value);
  								const label = selOpt ? selOpt.title : '';
  								return <Chip key={`${value}-${idx}`} label={label} className={classes.chip} />
  							})
  						}
  					</div>
  				)}
  				MenuProps={MenuProps}
  			>
  				{
  					options.map((opt) => <MenuItem key={opt.value} value={opt.value} {...menuItemProps}>
  							<Checkbox onChange={this.handleCheckbox} color='primary' checked={values.indexOf(opt.value) > -1} />
  							<ListItemText primary={opt.title} />
  						</MenuItem>
  					)
  				}
  			</Select>
  			{error && <FormHelperText>{helperText}</FormHelperText>}
  		</FormControl>
  	)
  }
}

export default withStyles(styles)(MultipleSelect);
