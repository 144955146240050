import {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import validate from '../Form/lib/validate';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';


const styles = (theme) => ({
	"menu": {
		"minWidth": "max-content",
		"padding": '1rem',
	},
	"icon": {
		"marginRight": theme.spacing(1),
	},
	"menuIcon": {
		"marginRight": theme.spacing(1),
	},
	"menuItemWithIcon": {
		"display": 'flex',
		"alignItems": 'center',
	},
	"menuItemWithInfoIcon": {
		"display": 'flex',
		"alignItems": 'center',
		"justifyContent": 'space-between',
	},
	"menuItemWithoutIcon": {
		"display": 'flex',
		"alignItems": 'center',
		"height": '12px'
	},
	"infoIcon":{
		"color":'#dcdcdc'
	}
});

class SelectField extends Component {
  onChange = (e) => {
  	const {name, value} = e.target;

  	const {valid, msg} = validate(value, this.props);
  	this.props.onChange(name, value, valid, msg);
  }

  renderOptions () {
  	const {enumTips} = this.props;
  	let {options} = this.props;
  	if (!this.props.required && !this.props.noEmptyOption) {
  		const emptyOption = {"title": 'None', "value": ''};
  		options = [emptyOption].concat(options);
  	}

  	return options.map((option, idx) => {
  		const className = option.info ? this.props.classes.menuItemWithInfoIcon : '';
  		return (
  			<MenuItem key={idx} value={option.value} className={className}>
  				{option.icon &&
            <ListItemIcon className={this.props.classes.menuIcon}>
            	{option.icon}
            </ListItemIcon>
  				}

  				{enumTips && enumTips[idx] && <Tooltip title={enumTips[idx]}>{option.title}</Tooltip>}
  				{(!enumTips || !enumTips[idx]) && option.title}
  				{option.info &&
            <ListItemIcon>
            	<Tooltip title={option.info} arrow>
            		<InfoIcon className={this.props.classes.infoIcon} />
            	</Tooltip>
            </ListItemIcon>
  				}
  			</MenuItem>
  		)
  	});
  }

  render () {
  	// eslint-disable-next-line no-unused-vars
  	const {classes, onChange, options, noEmptyOption, enumTips, tooltip, ...restProps} = this.props;
  	let selectPropsClass = classes.menuItemWithoutIcon;
  	const has_icon = this.props.options.find((opt) => opt.value === this.props.value)?.icon;
  	const has_info_icon = this.props.options.find((opt) => opt.value === this.props.value)?.info
  	if (has_icon) {
  		selectPropsClass = classes.menuItemWithIcon;
  	}
  	if (has_info_icon) {
  		selectPropsClass = classes.menuItemWithInfoIcon;
  	}
  	return (
  		<TextField
  			select
  			variant="outlined"
  			onChange={this.onChange}
  			InputLabelProps={{
  				"shrink": true,
  			}}
  			SelectProps={{
  				"classes": {
  					"icon": classes.icon,
  					"root": selectPropsClass,
  				},
  				"MenuProps": {
  					"className": `${classes.menu} ${classes.menuItemWithIcon}`,
  				},
  			}}
  			{...restProps}
  		>
  			{this.renderOptions()}
  		</TextField>
  	)
  }
}

export default withStyles(styles)(SelectField);
