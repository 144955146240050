class Routes {
  root= '/'
  error= '/error'
  verified= '/verified'
  connected= '/connected'
  financial_requirements= '/financial-requirements'
  finish= '/finish'
  tos= '/tos'
  account_agreement= '/account_agreement_v1'
  gpt= '/gpt'
  pp= '/pp'
  privacy= '/privacy'
  user= '/users'
  confirm_email= '/email/confirm'
  user_invitations= '/api/users'
  dashboard= '/dashboard'
  account= '/account'
  suppliers= '/companies/suppliers'
  kyc= "/companies/profile#tab=kyc"
  supplier= (company_id, supplier_id) => `/companies/${company_id}/suppliers/${supplier_id}`
  companies= '/companies'
  company_profile= '/companies/profile'
  financer= {
  	"root": '/financer',
  	"profile": '/financer/profile',
  	"companies": '/financer/companies',
  	"deals": '/financer/deals',
  	"repaid_invoices": '/financer/deals/repaid',
  	"treyd_invoices": '/financer/invoices',
  }
  operator= {
  	"root": '/operator',
  	"dashboard": '/operator/dashboard',
  	"companies": '/operator/companies',
  	"deals": '/operator/deals',
  	"repaid_invoices": '/operator/deals/repaid',
  	"treyd_invoices": '/operator/invoices',
  	"suppliers": '/operator/suppliers',
  }
  deals= '/deals'
  deal= (deal_id) => `/deals/${deal_id}`
  company= (company_id) => `/companies/${company_id}`
  deal_quality= (deal_id) => (`/deals/${deal_id}/quality`)
  treyd_invoices= '/deals/invoices'
  // For non auth users
  guest_signing= () => `/deals/:deal_id/users/:user_id`
  guest_deal_pdf= () => `/deals/:deal_id/users/:user_id/pdf`
  doc_signing= '/document/sign'
  doc_view= `/document/view`
  login=`${this.user}/login`
  forgot_password=`${this.user}/password/forgot`
  register=`${this.user}/register`
  home=this.dashboard
  financer_home=this.financer.deals
}

const ROUTES = new Routes()

export default Object.freeze(ROUTES);
