import {
	SET_FORM_DATA,
	UPDATE_FIELD_DATA,
	DELETE_FORM_DATA,
	SET_JSON_FORM_DATA,

} from '../actions';


export const deleteFormData = (key) => ({
	"type": DELETE_FORM_DATA,
	key,
})

export const setFormData = (key, data) => (dispatch) => {
	dispatch({
		"type": SET_FORM_DATA,
		"form": {
			key,
			data,
		},
	});
	return Promise.resolve();
}

export const setJsonFormData = (key, data) => (dispatch) => {
	dispatch({
		"type": SET_JSON_FORM_DATA,
		"form": {
			key,
			data,
		},
	});
	return Promise.resolve();
}

export const updateFieldData = (key, data, name) => (dispatch) => {
	dispatch({
		"type": UPDATE_FIELD_DATA,
		"form": {
			key,
			data,
			name,
		},
	});
	return Promise.resolve();
}
