import {merge} from 'lodash/fp';
import {useCallback} from 'react';
import {useIntl} from 'react-intl';


export default function formatNumber (params) {
	let {number, locale, style, currency, currencyDisplay, other_format_options, defaultVal} = params;
	if (style === 'currency' && !currency) {
		return number;
	}
	const other_opts = other_format_options ? ({...other_format_options}) : {};


	number = parseFloat(number);
	if (!number && number !== 0) {
		return defaultVal !== undefined ? defaultVal : '';
	}

	if (currency) {
		style = 'currency';
	}

	if (style === 'currency') {
		currencyDisplay = currencyDisplay || 'code';
	}
	else if (style === 'percent') {
		number = number / 100;
		other_opts.maximumFractionDigits = 2;
	}

	try {
		return new Intl.NumberFormat(locale || "en-SE", {style, currency, currencyDisplay, ...other_opts}).format(number)
	}
	catch (error) {
		console.warn(error);
		return number;
	}
}

export const format_currency = (amount, currency, other_format_options, locale = "en-SE") => {
	const default_opts = {"maximumFractionDigits": 0, "minimumFractionDigits": 0};
	const formatOptions = other_format_options ? merge(default_opts)(other_format_options) : default_opts;
	return formatNumber({"number": amount, "currency": currency?.toLowerCase(), "style": 'decimal', "other_format_options": formatOptions, locale});
};

export const format_amount_with_currency = (amount, currency, locale = "en-SE") => {
	const default_opts = {"maximumFractionDigits": 2, "minimumFractionDigits": 2};
	return formatNumber({"number": amount, "currency": currency?.toLowerCase(), "style": 'decimal', "other_format_options": default_opts, locale});
};

export const formatDecimal = (value, locale = "en-SE") => formatNumber({"number": value, "style": 'decimal', locale});


const formats = {
	'en-SE': {
		"thousandSeparator": ' ',
		"decimalSeparator": ','
	},
	'en-US': {
		"thousandSeparator": ' ',
		"decimalSeparator": ','
	},
	"default": {
		"thousandSeparator": '',
		"decimalSeparator": '.'
	}
}
export const number_formats = (country) => {
	const local = `en-${country.toUpperCase()}`;
	return formats[local] ? formats[local] : formats.default;
}


export const useFormats = () => {
	const {locale} = useIntl();

	const f_number = useCallback((params) => formatNumber({locale, ...params}), [locale]);

	const f_currency = useCallback((amount, currency, other_format_options) => format_currency(amount, currency, other_format_options, locale), [locale]);

	const f_amount_with_currency = useCallback((amount, currency) => format_amount_with_currency(amount, currency, locale), [locale]);

	const f_decimal = useCallback((value) => formatDecimal(value, locale), [locale]);

	return {"formatNumber": f_number, format_currency, f_currency, format_amount_with_currency, f_amount_with_currency, formatDecimal, f_decimal};
}