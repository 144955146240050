import './wdyr'; // <--- first import
import "./assets/css/vendor/bootstrap.min.css";
import "react-circular-progressbar/dist/styles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-table/react-table.css";
import 'react-image-lightbox/style.css';
import "video.js/dist/video-js.css";
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/se'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/se';

import blackLogo from './assets/img/treyd_square_logo_black.svg';
import whiteLogo from './assets/img/treyd_square_logo_white.svg';
import greenLogo from './assets/img/treyd_square_logo_green.svg';
import blueLogo from './assets/img/treyd_square_logo_blue.svg';
import {isMultiColorActive, defaultColor, themeColorStorageKey, isDarkSwitchActive} from "config/defaultValues";
import {stopWarningAsErrors} from "stopWarningAsErrors";


const setLogo = () => {
	const isDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
	const isStaging = process.env.REACT_APP_ENV === 'staging';
	const isDevelopment = process.env.REACT_APP_ENV === 'development';
	const icon = document.getElementById("favicon");
	if (isStaging) {
		icon.setAttribute("href", greenLogo)
	}
	else if (isDevelopment) {
		icon.setAttribute("href", blueLogo)
	}
	else {
		if (isDarkTheme) {
			icon.setAttribute("href", whiteLogo);
		}
		else {
			icon.setAttribute("href", blackLogo);
		}
	}
}

const color =
  (isMultiColorActive || isDarkSwitchActive) && localStorage.getItem(themeColorStorageKey)
  	? localStorage.getItem(themeColorStorageKey)
  	: defaultColor;

localStorage.setItem(themeColorStorageKey, color);
setLogo();
let render = () => {
	import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
		require('./AppRenderer');
	});
};
stopWarningAsErrors();
render();