import {Fragment} from 'react';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import LaunchIcon from '@material-ui/icons/Launch';
import IconButton from '@material-ui/core/IconButton';
import {PopPDF} from 'components/common/CustomModal';
import qs from 'query-string';
import {useToggle} from 'utils/generalHooks';
import {makeStyles} from '@material-ui/core/styles';
import {API_HOST_ROOT} from "config/api-config";
import {TreydPalette} from 'helpers/ThemeColors';

const treyd_palette = TreydPalette();

const useStyles = makeStyles({
	"root":{
		'&:hover': {
			"cursor": 'pointer',
		},
	},
	"link": {
		"display": 'flex',
		"color":  treyd_palette.blue.text,
		"transition": 'color $animation-time-short',
		'&:hover': {
			"cursor": 'pointer',
			"textDecoration": 'initial',
			"color":  treyd_palette.blue.text_light,
		},
		'&:active': {
			"textDecoration": 'initial',
			"color": treyd_palette.blue.text_light,
		}
	},
	"icon":{
		"color":treyd_palette.blue.darkest,
		'&:hover': {
			"color": treyd_palette.blue.dark,
		},

	}
});

export default function PreviewLink (props) {
	const classes = useStyles();
	const {value, children, hideIcon, preview, full_url, dowloadFromURL, button, className, ...restProps} = props;
	const [is_open, toggle] = useToggle();
	let url = '';
	if (value && !full_url) {
		if (dowloadFromURL) {
			url = value?.includes(API_HOST_ROOT) ? value : API_HOST_ROOT + value;
		}
		else {
			const parsedUrl = qs.parseUrl(value);
			parsedUrl.query.disposition = preview ? "inline" : "attachment";
			url = `${parsedUrl.url}?${qs.stringify(parsedUrl.query)}`;
			url = value?.includes(API_HOST_ROOT) ? value : API_HOST_ROOT + value;
		}
	}
	else if (full_url) {
		url = value;
	}
	const is_previewable = url?.toLocaleLowerCase()?.includes('.pdf')
	const Icon = is_previewable ? LaunchIcon : CloudDownloadIcon;

	return (
		<div className={classes.root}>
			{is_previewable &&
        <Fragment>
        	<div {...restProps} className={`${classes.link} ${className} d-flex`} onClick={toggle}>
        		{!hideIcon && !button && <Icon className={classes.icon} color='primary' />}
        		{!hideIcon && button &&
              <IconButton>
              	<Icon className={classes.icon} color='primary' />
              </IconButton>
        		}

        		{children &&
              <span style={{"marginLeft": '3px', "alignSelf":'flex-end'}}>
              	{children}
              </span>
        		}

        	</div>
        	<PopPDF url={url} text="View" is_open={is_open} toggle={toggle} external={full_url}/>
        </Fragment>
			}
			{!is_previewable &&
        <>
        	{/*eslint-disable-next-line react/jsx-no-target-blank*/}
        	<a href={url} {...restProps} target={is_previewable ? "_blank" : "_self"} className={`${classes.link} ${className} d-flex`}>
        		{!hideIcon && !button && <Icon className={classes.icon} color='primary' />}
        		{!hideIcon && button &&
              <IconButton>
              	<Icon className={classes.icon} color='primary' />
              </IconButton>
        		}
        		{children &&
              <span style={{"marginLeft": '3px', "alignSelf": 'flex-end'}}>
              	{children}
              </span>
        		}
        	</a>
        </>}
		</div>
	)
}