export default function formatFieldName (str) {
	if (!str) {
		return "";
	}
  
	if (str[0] === "_") {
		return str;
	}
  
	return str.split('_')
		.filter((s) => s.toLowerCase(s) !== 'id')
		.map((s) => s[0].toUpperCase() + s.slice(1))
		.join(' ');
}