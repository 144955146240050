import * as R from 'ramda';
import {
	SET_FORM_DATA,
	UPDATE_FIELD_DATA,
	DELETE_FORM_DATA,
	SET_JSON_FORM_DATA,
} from '../actions';


const dataValid = (data) => {
	for (let key in data) {
		if (!data[key].valid) return false;
	}
	return true;
}

// eslint-disable-next-line default-param-last
export default function reducer (forms = {}, action) {
	if (action.type === DELETE_FORM_DATA) {
		const {key} = action;
		const newForms = {...forms};
		delete newForms[key];
		return {...newForms};
	}
	else if (action.type === SET_FORM_DATA) {
		const {data, key} = action.form;
		const is_form_valid = dataValid(data);

		const newForm = {
			"data": R.clone(data),
			is_form_valid,
		};

		forms = {...forms, ...{[key]: newForm}};
	}
	else if (action.type === SET_JSON_FORM_DATA) {
		const {data, key} = action.form;
		const newData = R.clone(data)
		forms = {...forms, ...{[key]: newData}};
	}
	else if (action.type === UPDATE_FIELD_DATA) {
		const {data, key, name} = action.form;

		let newForm;
		if (!forms[key]) {
			newForm = {"data" : {}};
		}
		else {
			// Deep copy is required here, derefrencing the object is not engouh
			newForm = R.clone(forms[key]);
		}

		let field = newForm.data[name];
		if (!field) {
			field = {};
		}
		newForm.data[name] = {...field, ...data};
		newForm.is_form_valid = dataValid(newForm.data);

		forms = {...forms, ...{[key]: newForm}};
	}

	return forms;
};
