// eslint-disable-next-line no-undef
const envs = process.env;

const REACT_APP_PREFIX = "REACT_APP"
let env_prefix = REACT_APP_PREFIX;
let env_hasura_prefix = `${REACT_APP_PREFIX}_HASURA`;

if (envs.REACT_APP_ENV === 'development') {
	env_prefix = `${env_prefix}_DEV`;
	env_hasura_prefix = `${env_hasura_prefix}_DEV`;
}
else if (envs.REACT_APP_ENV === 'staging') {
	env_prefix = `${env_prefix}_STAGE`;
	env_hasura_prefix = `${env_hasura_prefix}_STAGE`;
}
else if (envs.REACT_APP_ENV === 'production') {
	env_prefix = `${env_prefix}_PROD`;
	env_hasura_prefix = `${env_hasura_prefix}_PROD`;
}

export const API_HOST_ROOT = envs[`${env_prefix}_HOST`];
export const API_HOST = `${API_HOST_ROOT}/api`;
export const hasura_host = envs[`${env_hasura_prefix}_HOST`];
export const LAGER_API_HOST = envs[`${env_prefix}_LAGER_HOST`];


export const USER_CONFIRM = '/users/confirmation'; //wil be migrated
export const LAGER_USER_CONFIRM = '/lager/auth/email/verify/';
export const LAGER_USER_CONFIRM_RESEND = '/lager/auth/resend-verify-email/';
export const LAGER_CHANGE_USER_EMAIL = '/lager/auth/change-email/';
export const USER_RESET_PASSWORD = '/users/password'; //wil be migrated
export const LAGER_USER_SEND_RESET_PASSWORD = '/lager/auth/password/forget/request/';
export const LAGER_USER_RESET_PASSWORD = '/lager/auth/password/forget/accept/';
export const COMPANY_SIGNATORIES_URL = (id) => `/companies/${id}/signatories`; //wil be migrated
export const getBLPAddOptURL = (id) => `/dataflow/blueprints/${id}/add_option`;

export const CANCEL_SIGN_ORDER_URL = (country, transactionId) => (country === 'dk' ? `/lager/auth/scrive/transaction/${transactionId}/cancel` : '/signatures/cancel_order'); //wil be migrated
export const CANCEL_SIGN_ORDER = '/signatures/cancel_order';
export const DOCUMENT_DATA_GUEST_URL = (doc_id) => `/document/${doc_id}/signature` //wil be migrated

export const ONFIDO_APPLICANT_SDK = '/lager/auth/onfido/applicant/';
export const ONFIDO_CREATING_CHECK = '/lager/auth/onfido/check/';


export const INVOICING_CURRENCIES_URL = '/lager/api/order/allowed-invoicing-currencies/';
export const USER_URL = '/users'; //wil be migrated
export const USER_SIGN_IN = '/lager/auth/token/obtain/';
export const USER_REFRESH_CLAIMS = '/lager/auth/token/refresh-claims/';
export const USER_REFRESH_TOKEN = '/lager/auth/token/refresh/';
export const USER_SIGN_UP = '/lager/auth/sign-up/';
export const SCRIVE_AUTH = '/lager/auth/scrive/auth/'
export const SCRIVE_AUTH_CHECKING = (transaction_id) => `/lager/auth/scrive/transaction/${transaction_id}`
export const USER_IDENTITY_VERIFICATION = (user_id)=>`${USER_URL}/${user_id}/verify_identity`; //wil be migrated
export const CURRENT_USER = `${USER_URL}/current`; //wil be migrated
export const LAGER_CURRENT_USER = "/lager/auth/token/verify-current-user/";
export const USER_INVITE = '/lager/auth/invitation/send/';
export const VALIDATE_TOKEN = '/lager/auth/token/verify/';
export const LINK_MEMBER_TO_USER = '/lager/auth/link-member/';
export const VALIDATE_INVITATION_TOKEN = '/lager/auth/invitation/verify/';
export const VALIDATE_PASSWORD_TOKEN = '/lager/auth/password/forget/verify/';
export const ACCEPT_INVITATION = '/lager/auth/invitation/accept/';
export const ADD_COMPANY_PLATFORM = '/lager/api/company';
export const COMPANY_ONBOARDING_STATUSES = (company_id)=>`/lager/api/companies/${company_id}/onboarding-statuses/`;
export const ADD_COMPANY = (country, org_no) => `/lager/api/company/${country}/${org_no}/createCompanyCore`;
export const ADD_MEMBER_TO_COMPANY = (company_id) => `/lager/api/company/${company_id}`;
export const LAGER_GOOGLE_AUTH = (auth_method)=>`/lager/auth/google/${auth_method}/`;

//Notificaitons
export const DISMISS_NOTIFICATIONS = (notification_id)=> `/lager/api/notifications/${notification_id}/dismiss`;
export const RECEIVE_NOTIFICATIONS = (notification_id)=> `/lager/api/notifications/${notification_id}/receive`;

//consents
export const ACCEPT_TOS = '/lager/api/consent/tos/';
export const ACCEPT_COMPANY_AGREEMENT = '/lager/api/consent/company_agreement/';

export const COMPANIES_URL = '/companies';
export const COMPANY_URL = (company_id) => `/companies/${company_id}`;
export const COMPANY_ATTORNEY = (company_id) => `/companies/${company_id}/power_of_attorneys`; //wil be migrated
export const CREATE_HUBSPOT_COMPANY = (id) => `${COMPANIES_URL}/${id}/create_hs_company`; //must be migrated


export const TEMPLATES_URL = '/templates';//wil be migrated
export const COMPANIES_TEMPLATES_URL = (company_id)=>`${TEMPLATES_URL}?templatable_id=${company_id}&templatable_type=Company`; //wil be migrated

export const DOCUMENTS_URL = (documentable_type, documentable_id) => `${documentable_type}/${documentable_id}/documents`; //wil be migrated
export const COMPANY_DOCUMENTS_URL = (id) => DOCUMENTS_URL('companies', id); //wil be migrated
export const DEAL_DOCUMENTS_URL = (id) => DOCUMENTS_URL('deals', id); //wil be migrated

export const DOCUMENT_SIGN = (document_id) => `/document/${document_id}/sign`; //wil be migrated
export const DOCUMENT_REQUEST_SIGNATURE = (documentable_type, documentable_id, document_id) => `${DOCUMENTS_URL(documentable_type, documentable_id)}/${document_id}/request_signature`; //wil be migrated


export const DEALS_URL = '/deals';

export const ORDER = (orderId)=>`/lager/api/order/${orderId}/`;

export const DEALS_PRODUCTS = (deal_id)=>`${DEALS_URL}/${deal_id}/products`;
export const DEALS_PRODUCTS_BULK_UPLOAD = (deal_id)=>`${DEALS_URL}/${deal_id}/bulk_create_products`;
export const DEALS_PRODUCTS_BULK_UPLOAD_TEMPLATE = (deal_id)=>`/api${DEALS_URL}/${deal_id}/bulk_create_products_template`;
export const COMPANY_LIQUIDITY_TEMPLATE = '/companies/liquidity_report_template'; //wil be migrated
export const DEAL_DUPLICATES = (deal_id)=>`/deals/${deal_id}/duplicate_payments`;
export const DEALS_LINE_ITEMS = (deal_id)=>`${DEALS_URL}/${deal_id}/deal_line_items`; 
export const DEALS_EMAILS = (deal_id)=>`${DEALS_URL}/${deal_id}/emails`;


export const START_DEAL_EVENT = (deal_id, event_name) => `${DEALS_URL}/${deal_id}/${event_name}`;
export const MAY_START_DEAL_EVENT = (deal_id, event_name) => `${DEALS_URL}/${deal_id}/may_${event_name}`;

export const BOOK_SOFEAST = (deal_id)=> START_DEAL_EVENT(deal_id, 'create_sofeast_booking');
export const REFRESH_SOFEAST = (deal_id)=> `${DEALS_URL}/${deal_id}/update_sofeast_booking`;

export const ACCEPT_QUALITY = (deal_id)=> START_DEAL_EVENT(deal_id, 'accept_quality');
export const DECLINE_QUALITY = (deal_id)=> START_DEAL_EVENT(deal_id, 'decline_quality');

export const PREVIEW_LOAN_AGREEMENT_PDF = '/lager/api/pdf_preview/loan_agreement';
export const PREVIEW_AML_PDF = '/lager/api/risk/aml/latest/pdf/';

//new invoices
export const GET_ALL_DEAL_INVOICES = (deal_id) => `/deals/${deal_id}/invoices`;
export const GET_INVOICE_BY_ID = (deal_id, invoice_id) => `/deals/${deal_id}/invoices/${invoice_id}`;
export const GET_INVOICES_BY_STATUS = (status) => `/invoices/${status}`;
export const CREATE_INVOICE = (deal_id) => `/deals/${deal_id}/invoices`;


//ERPS Integrations
export const ERP_GET = (org_no, country) => `/lager/api/erps/${country}/${org_no}`;
export const ERP_ONBOARD = (org_no, country) => `/lager/api/erps/${country}/${org_no}/onboard`;
export const  ERP_INTEGRATION = (integration_id)=>  `/lager/api/erps/integerations/${integration_id}`;
export const  CODAT_INTEGRATION_LINK = (integration_id)=>  `https://link.codat.io/company/${integration_id}`;
export const  MONTO_INTEGRATION_LINK = (integration_id)=>  `https://connect.monto.ai/${envs["REACT_APP_MONTO_TENANT_ID"]}/companies/${integration_id}/link/accounting`;
export const ERP_ACCOUNTING_DISCONNECT = (erp_id) => `/lager/api/erps/${erp_id}/accounting/disconnect`;
export const ERP_BANKING_DISCONNECT = (erp_id) => `/lager/api/erps/${erp_id}/banking/disconnect`;
//Xero Integrations
export const XERO_ACCOUNT_CREATE = (erp_id) => `/lager/api/erps/${erp_id}/xero/account/create`
export const XERO_ACCOUNTS_GET = (erp_id) => `/lager/api/erps/${erp_id}/xero/Account`
export const XERO_SETTINGS_GET = (erp_id) => `/lager/api/erps/${erp_id}/xero/settings/get`
export const XERO_SETTINGS_UPDATE = (erp_id)=> `/lager/api/erps/${erp_id}/xero/settings/update`
export const XERO_SUPPLIERS_GET =  (erp_id) => `/lager/api/erps/${erp_id}/xero/Supplier`
export const XERO_SUPPLIER_CREATE = (erp_id) => `/lager/api/erps/${erp_id}/xero/supplier/create`

// export const FX_RATES='/fx_rates'

export const FINANCIER_URL = "/financiers";
export const FINANCER_USERS = (id)=>`${FINANCIER_URL}/${id}/users` //wil be migrated

export const IMPERSONATE = "/lager/auth/impersonate/";

export const HUBSPOT_COMPANIES = "/hubspot/filter_companies";
export const HUBSPOT_COMPANIES_SEARCH = (q)=>`${HUBSPOT_COMPANIES}?q=${q}`;

export const protocol = {http: "https", ws: "wss"};
export const COMPANY_PRICES_URL = (company_id) => `/lager/api/companies/${company_id}/pricing/`;
