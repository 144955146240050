import {Card as MuiCard} from "@material-ui/core";


export const Card = ({hideBorder, children, ...rest}: any) => {
	const style = hideBorder ? {border: "none", boxShadow: "none"} : {};
	return <MuiCard style={style}{...rest}>
		{children}
	</MuiCard>
}

export default Card;