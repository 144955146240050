import request from './requestWrapper';


export default function deleteReq (url, options = {}) {
	const {noToast, noToastOnSuccess} = options;

	return request({
		"method": 'DELETE',
		"headers": {
			'content-type': 'application/json',
		},
		url,
		noToast,
		noToastOnSuccess,
	});
}
