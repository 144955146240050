/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_USER_STATUSES = "SET_USER_STATUSES";
export const SET_DEAL_STATUSES = "SET_DEAL_STATUSES";
export const SET_CURRENCIES = "SET_CURRENCIES";
export const SET_AUTH_METHOD = "SET_AUTH_METHOD";
export const SET_TABLE_USER_PREF = "SET_TABLES_USER_PREF";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = "MENU_CHANGE_HAS_SUB_ITEM_STATUS";


/* FORM */
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const UPDATE_FIELD_DATA = 'UPDATE_FIELD_DATA';
export const DELETE_FORM_DATA = "DELETE_FORM_DATA";

/* JSONForm */
export const SET_JSON_FORM_DATA = 'SET_JSON_FORM_DATA';
/* AUTH */
export const SET_CREDENTIALS = "SET_CREDENTIALS";
export const SET_ERP = "SET_ERP";
export const SET_CURRENT_COMPANY = "SET_CURRENT_COMPANY";
export const SET_USER_COMPANIES = "SET_USER_COMPANIES";


/* DEAL */
export const UPDATE_RECENT_DEALS = 'UPDATE_RECENT_DEALS';
export const SET_RECENT_DEAL_OPEN = 'SET_RECENT_DEAL_OPEN'; 
export const SET_ALL_DEAL_OPEN = 'SET_ALL_DEAL_OPEN'; 
export const SET_COMPANY_DEALS = 'SET_COMPANY_DEALS';
export const SET_CURRENT_DEAL = 'SET_CURRENT_DEAL';
export const SET_CURRENT_DEAL_WARNINGS = 'SET_CURRENT_DEAL_WARNINGS';
export const SET_CURRENT_DEAL_ERRORS = 'SET_CURRENT_DEAL_ERRORS';
export const SET_IS_DEAL_SUBMIT_CLICKED = "SET_IS_DEAL_SUBMIT_CLICKED"
export const SET_ACTIVE_DEAL_FORM_DATA = 'SET_ACTIVE_DEAL_FORM_DATA';

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./credentials/actions";
export * from "./forms/actions";
export * from "./generalData/actions";
export * from "./deals/actions";
