/* eslint-disable prefer-destructuring */
import {Dialog, DialogContent} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import get from 'api/get';
import {getBLPAddOptURL} from 'config/api-config';
import {Component, createRef} from 'react';
import {formatFieldOptions} from 'utils/formatSchemas';
import mapSelectOption from 'utils/mapSelectOption';
import objsEq from 'utils/objsEq';
import search from 'utils/search';
import AutoCompletePopper from '../AutoCompletePopper/AutoCompletePopper';
import Form from "../Form/Form";
import validate from '../Form/lib/validate';



const styles = (theme) => ({
	"subheader": {
		"backgroundColor": 'white',
		"textAlign": 'center',
		"paddingLeft": '8px',
		"justifyContent": 'left'
	},
	"menuItem": {
		'&:hover': {
			"cursor": 'pointer',
			"backgroundColor": theme.palette.primary.light,
		}
	},
	"noSearchField": {
		'&:hover': {
			"cursor": 'pointer',
		}
	},
	"container": {
		"display": 'inline-block',
		"width": '100%',
	},
	"addButton": {
		"textTransform": 'none',
		"justifyContent": 'left',
	},
	"addIcon": {
		"marginRight": '4px'
	},
	"inputBaseRoot": {
		"paddingRight": '4px',
	}
});


class ComboBox extends Component {
	constructor (props) {
		super(props);

		this.inputRef = createRef();
		this.contRef = createRef();

		this.state = {
			"loading": false,
			"options": this.props.options || [],
			"menuOpen": false,
			"selectedName": '',
			"searchTerm": '',
			"isCreateItemModalOpen": false,
			"isLoadingCreateItemSchema": false,
			"createForm": null,
		};

		if (this.state.options && this.state.options.length > 0) {
			this.state.selectedName = this.getValueName();
		}

		this.getValueName = this.getValueName.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);
		this.handleClickAway = this.handleClickAway.bind(this);
		this.backendSearch = this.backendSearch.bind(this);
		this.searchTimeOutKey = null;
	}

	componentDidMount () {
		// It's important to bind on the document and not document.body
		// because we want it to run after the bound
		document.addEventListener('click', this.handleClickAway);
		const {backendSearchProps} = this.props;
		if (backendSearchProps && backendSearchProps.autoFetch) {
			this.autoFetch();
		}
	}

	componentWillUnmount () {
		document.removeEventListener('click', this.handleClickAway);
	}

	autoFetch () {
		const {backendSearchProps} = this.props;
		if (!backendSearchProps && !backendSearchProps.autoFetch) {
			return;
		};

		get(backendSearchProps.searchURL).then((resp) => {
			const options = backendSearchProps.formatSearchResult(resp.data);
			this.setState({
				options,
				"selectedName": this.getValueName(options),
			});
		})
	}

	handleClickAway (e) {
		if (this.state.menuOpen) {
			const node = e.target;
			const parent = this.contRef.current;
			if (!parent.contains(node)) {
				this.setState({
					"menuOpen": false,
					"selectedName": this.getValueName()
				});
			}
		}
	}

	componentDidUpdate (prevProps) {
		const {options, value} = this.props;
		if (!objsEq(prevProps.value, value)) {
			this.setState({"selectedName": this.getValueName()});
		}

		if (!objsEq(prevProps.options, options)) {
			this.setState({
				options,
				"selectedName": this.getValueName(options),
			});
		}
	}

	getValueName (options) {
		const {value} = this.props;
		options = options ? options : this.state.options;
		return mapSelectOption(value, options) || '';
	}

	toggleMenu (_event) {
		this.setState((prevState) => ({"menuOpen": !prevState.menuOpen, "searchTerm": ''}));
	}

	backendSearch (value) {
		this.setState({
			"menuOpen": true,
			"loading": true,
			"selectedName": value,
		});

		if (this.searchTimeOutKey) {
			clearTimeout(this.searchTimeOutKey);
		}

		this.searchTimeOutKey = setTimeout(() => {
			const {backendSearchProps} = this.props;
			const params = {[backendSearchProps.searchParamKey]: value};

			get(backendSearchProps.searchURL, {params}).then((resp) => {
				const options = backendSearchProps.formatSearchResult(resp.data);
				this.setState({
					options
				});
			}).finally(() => {
				this.setState({
					"loading": false,
				});
			})
		}, 300);
	}

	handleInput (e) {
		const {value} = e.target;
		if (this.props.backendSearch) {
			this.backendSearch(value);
		}
		else {
			this.setState({
				"menuOpen": true,
				"selectedName": value,
				"searchTerm": value,
			});
		}
	}

	handleSelect (event, title, value) {
		this.setState({
			"selectedName": value ? title : '',
			"menuOpen": false,
		});

		const {valid, msg} = validate(value, this.props);
		this.props.onChange(this.props.name, value, valid, msg, event);
	}

	renderOptions () {
		let options;

		if (this.state.loading) {
			return (
				<ListItem
					selected={true}
					className={this.props.classes.menuItem}
				>
					<CircularProgress color='primary' fontSize='small' />
				</ListItem>
			)
		}
		else if (this.props.backendSearch) {
			options = this.state.options.slice(0, 200);
		}
		else {
			options = this.state.options;
			options = this.props.noSearch ? options : search(options, this.state.searchTerm);
		}

		// if (!this.props.required) {
		//   const emptyOption = { title: 'None', value: '' };
		//   options = [emptyOption].concat(options);
		// }

		return options.map((option, idx) => {
			const {title, value} = option;
			const selected = value === this.props.value;

			return (
				<ListItem
					key={`${value}-${idx}`}
					value={value}
					selected={selected}
					onClick={(e) => this.handleSelect(e, title, value)}
					className={this.props.classes.menuItem}
				>
					<ListItemText primaryTypographyProps={{"variant": "body2"}} primary={title} />
				</ListItem>
			)
		});
	}

  fetchCreateItemSchema = () => {
  	this.setState({"isLoadingCreateItemSchema": true});
  	get(this.props.blueprintURL)
  		.then((response) => {
  			const {
  				"schema": {
  					saveURL,
  					fields,
  				},
  			} = response.data;

  			fields.forEach((field) => {
  				if (field.options) {
  					field.options = formatFieldOptions(field.options, 0, 1)
  				}

  				if (field.key === "name") {
  					field.defaultValue = this.state.selectedName;
  				}
  			});

  			this.setState({
  				"createForm": {
  					"onSuccess": this.onAddItemSuccess,
  					"create": true,
  					"schema": [...fields],
  					"url": saveURL,
  				}
  			});
  		})
  		.finally(() => {
  			this.setState({"isLoadingCreateItemSchema": false});
  		});
  }

  closeCreateItemModal = (clickAway) => {
  	if (!this.state.isLoadingCreateItemSchema) {
  		this.setState({
  			"isCreateItemModalOpen": false,
  			"createForm": null
  		});

  		// Reset to the last selected value when closing the modal without submitting
  		if (clickAway) {
  			this.setState({
  				"selectedName": this.getValueName()
  			});
  		}
  	}
  }

  onAddItemSuccess = (response, fromBlueprint) => {
  	let title, value;
  	if (fromBlueprint) {
  		title = response.data.value;
  		value = response.data.value;
  	}
  	else {
  		title = response.data.name;
  		value = response.data.id;
  	}

  	this.addOption(title, value);
  	this.closeCreateItemModal();
  	this.handleSelect(null, title, value);
  }

  addOption = (title = "", value = "") => {
  	this.setState((prevState) => {
  		const {options} = prevState;
  		options.push({title, value});
  		return {options};
  	});
  }

  checkExistingValue = (value = "") => {
  	value = value.trim();
  	const existingValue = this.state.options.find((o) => o.title.trim() === value);
  	return Boolean(existingValue);
  }

  addItem = () => {
  	let value = this.state.selectedName;
  	if (!value) {
  		return;
  	}
  	value = value.trim();

  	const existingValue = this.checkExistingValue(value);
  	if (existingValue) {
  		return;
  	}

  	const blID = this.props.blueprint_id;
  	if (this.props.blueprintURL) {
  		this.setState({"isCreateItemModalOpen": true, "menuOpen": false});
  		this.fetchCreateItemSchema();
  	}
  	else if (blID) {
  		const createForm = {
  			"url": getBLPAddOptURL(blID),
  			"create": false,
  			"onSuccess": (resp) => this.onAddItemSuccess(resp, true),
  			"schema": [
  				{
  					"key": 'key',
  					"type": 'hidden',
  					"defaultValue": this.props.name,
  				},
  				{
  					"key": 'value',
  					"label": this.props.label,
  					"type": 'text',
  					"defaultValue": value,
  				}
  			]
  		};
  		this.setState({createForm, "isCreateItemModalOpen": true, "menuOpen": false});
  	}
  	else {
  	}
  }

  canShowAddButton () {
  	const {addBtnRender, noAddButton, backendSearch, blueprintURL, blueprint_id, onAddClick} = this.props;
  	return addBtnRender || onAddClick || (
  		!noAddButton &&
      !backendSearch &&
      (blueprintURL || blueprint_id) &&
      !this.state.loading &&
      this.state.selectedName &&
      !this.checkExistingValue(this.state.selectedName)
  	);
  }
  handelTextFieldFocus () {
  	this.inputRef.current.focus();
  }

  render () {
  	const {
  		// eslint-disable-next-line no-unused-vars
  		options, onChange, classes, value, noSearch, blueprint_id, backendSearch, noAddButton, backendSearchProps, style, className, addBtnRender, shrink, ExternalAddButton, addBtnLabel, onAddClick,
  		...props
  	} = this.props;

  	// to aviod being sent to the dom
  	delete props.blueprintURL;

  	const showAddButton = this.canShowAddButton();

  	const noSearchInputProps = {};
  	if (noSearch) {
  		noSearchInputProps['onClick'] = this.toggleMenu;
  		noSearchInputProps['inputProps'] = {
  			"disabled": true,
  			"className": classes.noSearchField,
  		}
  	}

  	return (
  		<div className={`CustomComboBox-${props.variant || "outlined"} ${classes.container} ${className}`} style={style} ref={this.contRef}>
  			<TextField
  				onFocus={(e) => e.target.select()}
  				autoComplete='off'
  				disabled={props.disabled}
  				variant={props.variant || "outlined"}
  				onChange={this.handleInput}
  				onClick={this.toggleMenu}
  				value={this.state.selectedName}
  				inputRef={this.inputRef}
  				fullWidth
  				{...props}
  				InputLabelProps={{"shrink": props.placeholder ? true : undefined}}
  				classes={{
  					"root": classes.root
  				}}
  				InputProps={{
  					"classes": {
  						"root": classes.inputBaseRoot,
  					},
  					...noSearchInputProps,
  					"endAdornment": (
  						!props.disabled && <InputAdornment position="end">
  							<IconButton onClick={(e) => {
  								e.preventDefault();
  								e.stopPropagation();
  								this.toggleMenu()
  								this.handelTextFieldFocus();
  							}}>
  								{<ArrowDropDownIcon />}
  							</IconButton>
  						</InputAdornment>
  					),
  				}}
  			/>

  			{this.state.menuOpen && !props.disabled &&
          <AutoCompletePopper inputRef={this.inputRef}>
          	<List
          		onClick={(e) => e.nativeEvent.stopImmediatePropagation()}
          		data-testid="comboBox_list"
          	>
          		{showAddButton &&
                <ListSubheader className={classes.subheader} key={`${value}-sticky`}>
                	{addBtnRender &&
                    <div onClick={() => this.setState({"menuOpen": false})}>
                    	{addBtnRender()}
                    </div>
                	}

                	{onAddClick &&
                    <Button fullWidth color='primary' className={classes.addButton} onClick={
                    	() => {
                    		this.setState({menuOpen: false});
                    		onAddClick()
                    	}} >
                    	<AddCircleOutlineIcon className={classes.addIcon} />
                    	{addBtnLabel}
                    </Button>
                	}
                </ListSubheader>
          		}

          		{this.renderOptions()}
          	</List>
          </AutoCompletePopper>
  			}

  			<Dialog
  				open={this.state.isCreateItemModalOpen}
  				onClose={() => this.closeCreateItemModal(true)}
  				PaperProps={{"style": {"minWidth": '30%'}}}
  			>
  				<DialogContent>

  					{this.state.isLoadingCreateItemSchema && (
  						<CircularProgress color='primary' fontSize='small' />
  					)}

  					{this.state.createForm &&
              <Form
              	editMode
              	enableSbOnLoad
              	{...this.state.createForm}
              />
  					}
  				</DialogContent>
  			</Dialog>
  		</div>
  	)
  }
}

export default withStyles(styles)(ComboBox);