export function formatFieldOptions (options, titleKey, valueKey) {
	if (!options || !options.length) {
		return [];
	}
	else {
		if (titleKey && valueKey) {
			return options.map((o) => ({"title": o[titleKey], "value": o[valueKey]}));
		}
		// If each option is a list
		else if (typeof (options[0]) === typeof ([])) {
			return options.map((o) => ({"title": o[0], "value": o[1]}));
		}
		// If each option is a single vlaue
		else {
			return options.map((o) => ({"title": o, "value": o}));
		}
	}
}
