import {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import FormFields from '../FormFields/FormFields';
import DeleteButton from '../DeleteButton/DeleteButton';
import SaveButton from '../SaveButton/SaveButton';
import {Row, Col} from '../Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import getValue from './lib/getValue';
import validate_data_schema, {dataValid} from './lib/validate_data_schema';
import save from 'api/save';
import deleteReq from 'api/deleteReq';
import {updateFieldData, setFormData, deleteFormData} from 'redux/actions';
import format_data_for_save from './lib/format_data_for_save';
import isEqual from "react-fast-compare";
import {path as R_path} from 'ramda';


class Form extends Component {
  static propTypes = {
  	"schema": PropTypes.array,
  	"getSchema": PropTypes.func,
  	"data": PropTypes.object,
  	"url": PropTypes.string,
  	"editMode": PropTypes.bool,
  	"enterSubmit": PropTypes.bool,
  	"submitText": PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  	// Used format the data sent when submitting
  	"isJsonSchema": PropTypes.bool,
  	// Indicates wether it's a POST or PUT request
  	"create": PropTypes.bool,
  	/*
      Called before making the submit request and after validation.
      function (data) => Promise | null
    */
  	"onSubmit": PropTypes.func,
  	"CustomSubmit": PropTypes.elementType,
  	"isCenterSubmitButton":PropTypes.bool,
  	"onError":PropTypes.func,
  	"onSuccess":PropTypes.func,
  	"formatBeforeSb": PropTypes.func,
  	"saveRequestOptions": PropTypes.object,
  	"customLoading": PropTypes.bool,
  }

  constructor (props) {
  	super(props);
  	this.state = {
  		"create": props.create,
  		"url": props.url,
  		"savedData": null, // used to roll back to the last data saved in database
  		...this.initState(props),
  		"data": {},
  		"is_form_valid": true,
  		"submit_clicked": false,
  	};

  	this.autoSaveTimeoutKey = null;
  	props.set_ref && props.set_ref(this);
  }

  get schema () {
  	return this.props.getSchema ? this.props.getSchema(this.state.data) : this.props.schema;
  }
  get store_key () {
  	return this.props.store_key;
  }

  componentDidMount () {
  	this.initData(this.props);
  }

  componentWillUnmount () {
  	if (this.store_key) {
  		this.props.deleteFormData(this.store_key);
  	}
  }

  shouldComponentUpdate (nextProps, nextState) {
  	if (!this.store_key) return true;

  	const {"form": cur_form, ...cur_props} = this.props;
  	const {"form": next_form, ...next_props} = nextProps;
  	// We make the checks in the order of the more likely change + less comparison cost
  	if (!isEqual(this.state, nextState)) return true;
  	else if (!isEqual(cur_form, next_form)) return true;
  	else if (!isEqual(cur_props, next_props)) return true;
  	else return false;
  }

  componentDidUpdate (prevProps, prevState) {
  	const curProps = this.props;
  	let schema_changed = false;
  	if (curProps.getSchema) {
  		//Change schema based on datachange
  		schema_changed = !isEqual(prevProps.getSchema(prevState.data), curProps.getSchema(this.state.data));
  	}
  	else {
  		schema_changed = !isEqual(prevProps.schema, curProps.schema);
  	}
  	const default_data_changed = !isEqual(prevProps.data, curProps.data);

  	if (curProps.submit_clicked && curProps.submit_clicked !== prevProps.submit_clicked) {
  		// The reason we need this is because we don't want to show the error msgs before the user click on submit
  		// This is used for nested forms
  		this.validateAll();
  	}

  	if (schema_changed) {
  		// TO DO: This cause the helperText for required fields to show up when any changes are made to the schema
  		// Do we even need this, or do we just need to update the formatting of the data based on the new schema
  		this.validateAll();
  	}

  	if (default_data_changed) {
  		this.initData(curProps);
  	}
  	else if (prevProps.clearChangesTogg !== this.props.clearChangesTogg) {
  		this.setState({...this.initState()});
  		this.initData(curProps, this.state.savedData);
  	}

  	if (!isEqual(prevProps.create, curProps.create)) {
  		this.setState({"create": curProps.create})
  	}

  	if (!isEqual(prevProps.url, curProps.url)) {
  		this.setState({"url": curProps.url})
  	}

  	// We update the stack layout because the height can change when changing the editMode
  	if (this.props.updateStackLayout && curProps.editMode !== prevProps.editMode) {
  		this.props.updateStackLayout();
  	}
  }

  initState () {
  	return {"formChanged": false, "submit": ''};
  }

  // Get form data from redux
  get form () {
  	if (this.store_key) {
  		const {form} = this.props;
  		return form || {"data": {}};
  	}
  	else {
  		const {data, is_form_valid} = this.state;
  		return {data, is_form_valid};
  	}
  }

  get is_form_valid () {
  	return this.form.is_form_valid;
  }

  initData (props, savedData) {
  	let data = {};

  	if (savedData) {
  		// For when we want to clear the form to the last submitted data
  		data = savedData;
  	}
  	else if (props.data) {
  		// eslint-disable-next-line prefer-destructuring
  		data = props.data;
  	}

  	const formattedData = {}; // We will use a new object as to not edit the props obj
  	const state_data = this.form.data;

  	this.schema
  		.filter((field) => (field.type !== 'section') && !field.view_only)
  		.forEach((field) => {
  			let {value, valid, msg} = getValue(field, data[field.key], false);

  			// If the value doesn't change use the old valid flag
  			const old_value = R_path([field.key, 'value'], state_data)
  			if (isEqual(old_value, value)) {
  				valid = R_path([field.key, 'valid'], state_data);
  				msg = R_path([field.key, 'msg'], state_data);
  			}

  			formattedData[field.key] = {value, valid, msg};
  		});

  	if (this.store_key) {
  		this.props.setFormData(this.store_key, formattedData);
  	}
  	else {
  		this.setState({"is_form_valid": true, "data": formattedData});
  	}
  }

  updateValue = (name, value, valid, msg) => {
  	if (this.state.submit === 'inProgress') {
  		return;
  	}

  	const after_update = () => {
  		if (this.props.onChange) this.props.onChange(name, value, valid, msg);
  		if (this.props.autoSave) this.autoSave(name);
  	};

  	if (this.store_key) {
  		const promise = this.props.updateFieldData(this.store_key, {value, valid, msg}, name);
  		this.setState({"formChanged": true});
  		promise.then(after_update);
  	}
  	else {
  		this.setState((prevState) => {
  			const {data} = prevState;
  			data[name] = {value, valid, msg};
  			return {data, "is_form_valid": dataValid(data), "formChanged": true};
  		}, after_update)
  	}
  }

  autoSave = (name) => {
  	const {autoSave} = this.props;
  	const {schema} = this;

  	if (autoSave) {
  		if (this.autoSaveTimeoutKey) {
  			clearTimeout(this.autoSaveTimeoutKey);
  		}

  		const field = schema.find((f) => f.key === name);

  		const default_auto_save_delay = this.props.autoSaveDelay || 700;
  		const delay = field && field.autoSaveDelay ? field.autoSaveDelay : default_auto_save_delay;

  		this.autoSaveTimeoutKey = setTimeout(this.onSb, delay);
  	}
  }

  /*
    Runs the validation function on all inputs.
    Note that checking the valid attribute in `state.data[key].valid` is not enough,
      because at the start of the component life cycle the all inputs have valid set to true.
    So we have to run this function before submit to make sure that inputs are actually valid.
  */
  validateAll = () => {
  	const {data, valid} = validate_data_schema(this.schema, this.form.data);

  	if (this.store_key) {
  		return this.props.setFormData(this.store_key, data);
  	}
  	else {
  		return new Promise((resolve) => {
  			this.setState({data, "is_form_valid": valid}, resolve());
  		});
  	}
  }

  getDataKey () {
  	return this.props.dataKey ? this.props.dataKey : (this.props.isJsonSchema ? 'data' : '');
  }


  onKeyDown = (event) => {
  	// 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
  	if (event.key === 'Enter' || event.key === "NumpadEnter") {
  		event.preventDefault();
  		event.stopPropagation();
  		this.onSb(event);
  	}
  }

  onSb = (e) => {
  	if (e) {
  		e.preventDefault();
  	}

  	if (this.props.nested_form) {
  		let data = format_data_for_save(this.schema, this.form.data, this.props.enctype, this.getDataKey());
  		this.props.onSubmit(data, this.is_form_valid);
  		return;
  	}

  	this.validateAll().then(() => {
  		// Used for nested forms
  		this.setState({"submit_clicked": true});

  		if (this.is_form_valid) {
  			let data = format_data_for_save(this.schema, this.form.data, this.props.enctype, this.getDataKey());
  			if (this.props.formatBeforeSb) {
  				data = this.props.formatBeforeSb(data);
  			}

  			let promise;
  			if (this.props.onSubmit) {
  				promise = this.props.onSubmit(data);
  				if (!promise) {
  					this.setState({"formChanged": false})
  					return;
  				}
  			} else {
  				const method = this.state.create ? 'POST' : 'PUT';
  				const saveRequestOptions = this.props.saveRequestOptions || {};
  				promise = save(this.state.url, data, method, {...saveRequestOptions});
  			}

  			this.setState({"submit": 'inProgress'});
  			promise.then((resp) => {
  				let newState = {
  					"submit": 'success',
  					"savedData": this.getDataKey() ? resp.data[this.getDataKey()] : resp.data,
  				};

  				if (this.props.clearOnSuccess) {
  					newState = {
  						...newState,
  						...this.initState(),
  						"create": this.props.create,
  						"url": this.props.url
  					};
  					this.props.initData(this.props);
  				}
  				else if (this.state.create) {
  					newState = {
  						...newState,
  						"create": false,
  						"url": resp.data.url
  					}
  				}

  				const callback = () => {
  					if (this.props.onChange) {
  						this.props.onChange(false);
  					}

  					if (this.props.onSuccess) {
  						this.props.onSuccess(resp, this.props.formKey);
  						this.setState({"formChanged": false})
  					}
  				}

  				this.setState(newState, callback);
  			}).catch((err) => {
  				this.setState({"submit": 'failed'});
  				if (this.props.onError) {
  					this.props.onError(err);
  				}
  			});
  		}
  	});
  }

  deleteInstance = () => {
  	if (this.props.onDelete) {
  		return this.props.onDelete();
  	}
  	else if (!this.state.create) {
  		return deleteReq(this.state.url).then(() => {
  			this.props.onDeleteSuccess(this.props.formKey);
  		});
  	}
  	else if (this.props.onDeleteSuccess) {
  		this.props.onDeleteSuccess(this.props.formKey);
  	}
  }

  render () {
  	const {editMode, autoSave, submitText, inline, fieldsOnly, updateStackLayout, hideDelete, isCenterSubmitButton, CustomSubmit, customLoading} = this.props;
  	const {schema} = this;
  	const {data} = this.form;
  	const {submit_clicked} = this.state;

  	const formFields = (
  		<FormFields
  			submit_clicked={submit_clicked}
  			onChange={this.updateValue}
  			hideEmptyField={this.props.hideEmptyField}
  			schema={schema}
  			data={data}
  			editMode={editMode}
  			updateStackLayout={updateStackLayout}
  		/>
  	);

  	const loadingCircle = (
  		this.state.submit === 'inProgress' &&
      <CircularProgress variant="indeterminate" size={30} thickness={4} />
  	);

  	const disableSb = !this.is_form_valid ||
      (!this.state.formChanged && !this.props.enableSbOnLoad) ||
      this.state.submit === 'inProgress';

  	const show_submit = editMode && !autoSave && !this.props.hide_submit;
  	const tabIndex = this.props.enterSubmit ? -1 : undefined; // to make the dib focusable but at low level, so that we can add onKeyDown
  	return (
  		<div style={{"width": '100%'}}>
  			{!(inline || fieldsOnly) &&
          <div onKeyDown={this.onKeyDown} tabIndex={tabIndex}>
          	{/* formFields should be wrapped in a Row */}
          	{formFields}
          	{
          		!CustomSubmit &&
              <div style={{"display": show_submit ? 'flex' : 'none', "justifyContent": isCenterSubmitButton ? 'center' : 'flex-end', "alignItems": 'center', "margin": '1rem'}} >
              	<div style={{"marginRight": '1rem'}}>{loadingCircle}</div>
              	<div>
              		{show_submit &&
                    <ConfirmWrapper
                    	onConfirm={this.onSb}
                    	confirm_before_save={this.props.confirm_before_save}
                    	renderButton={(onClick) => <Button
                    			style={{
                    				"fontWeight": 700,
                    				"fontSize": '0.85rem',
                    				"width": 'auto',
                    				"minWidth": '125px',
                    				"borderRadius": 50
                    			}}
                    			data-test='form-submit'
                    			onClick={onClick}
                    			variant="contained"
                    			disabled={disableSb}
                    			color='primary'
                    		>
                    			{submitText || "Submit"}
                    		</Button>
                    	}
                    />
              		}
              	</div>
              </div>
          	}
          	{
          		CustomSubmit &&
              <>
              	{!customLoading && <div style={{"marginRight": '1rem'}}>{loadingCircle}</div>}
              	<div>
              		{show_submit &&
                    <ConfirmWrapper
                    	onConfirm={this.onSb}
                    	confirm_before_save={this.props.confirm_before_save}
                    	renderButton={(onClick) => <CustomSubmit
                    			data-test='form-submit'
                    			onClick={onClick}
                    			variant="contained"
                    			disabled={disableSb}
                    			color='primary'
                    			loading={this.state.submit === 'inProgress'}
                    		>
                    			{submitText || "Submit"}
                    		</CustomSubmit>
                    	}
                    />
              		}
              	</div>
              </>
          	}
          </div>
  			}

  			{fieldsOnly &&
          <Fragment>
          	{formFields}
          </Fragment>
  			}

  			{inline &&
          <Row>
          	<Col xs={editMode ? 11 : 12} md={editMode ? (autoSave ? 11 : 10) : 12}>
          		{formFields}
          	</Col>

          	{loadingCircle &&
              <Col xs={6} md={1}>
              	{loadingCircle}
              </Col>
          	}

          	{!loadingCircle && show_submit &&
              <Col xs={6} md={1}>
              	<SaveButton onClick={this.onSb} disabled={disableSb} />
              </Col>
          	}

          	{!loadingCircle && editMode && !hideDelete &&
              <Col xs={1}>
              	<DeleteButton onClick={this.deleteInstance} />
              </Col>
          	}
          </Row>
  			}
  		</div>
  	);
  }
}


const ConfirmWrapper = ({renderButton, onConfirm, confirm_before_save}) => {
	if (confirm_before_save) {
		const {title, body} = confirm_before_save;
		return <ConfirmDialog
			title={title}
			body={body}
			onConfirm={onConfirm}
			renderButton={renderButton}
		/>
	}
	else {
		return renderButton(onConfirm);
	}
}


const mapStateToProps = ({forms}, ownProps) => (ownProps.store_key ? {"form": forms[ownProps.store_key]} : {});

export default connect(
	mapStateToProps,
	{setFormData, updateFieldData, deleteFormData},
)(Form);

