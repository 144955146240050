

export type FeatureFlagType = 'accounting-software-provider' | 'accounting-software-provider-erp' | 'accounting-software-provider-erp-reconnect' 
| 'accounting-software-provider-erp-monto' | 'accounting-software-provider-erp-asteria' | 'accounting-software-provider-erp-xero-writeback' 
| 'accounting-financial-document-renew' | 'signature-methods-sms';

export const defaultFeatureFlags: Record<FeatureFlagType, boolean> = {
	'accounting-software-provider': true,
	'accounting-software-provider-erp': true,
	'accounting-software-provider-erp-reconnect': false,
	'accounting-software-provider-erp-monto': true,
	'accounting-software-provider-erp-asteria': true,
	'accounting-software-provider-erp-xero-writeback': false,
	'accounting-financial-document-renew': true,
	'signature-methods-sms': false,
}