import {Fragment, Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Form from '../Form/Form';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Close';


const styles = () => ({
	"cont": {
		"marginBottom": '1rem',
	},
	"forms_cont": {
		"marginLeft": '1rem',
	},
	"title": {
		"marginBottom": '1rem',
		"fontSize": '1rem',
	},
	"seperator": {
		"margin": '1rem 0 1.5rem 0',
	},
	"removeIcon": {
		"cursor": "pointer",
		"margin": '0.5rem 0.7rem',
	},
	"form_cont": {
		"display": 'flex',
		"flexDirecton": 'row',
	}
});

class MultipleForm extends Component {
  add_item = () => {
  	const data_list = [...this.props.value];
  	data_list.push({});
  	this.props.onChange(this.props.name, data_list, true, '');
  }

  remove_item = (idx) => {
  	const data_list = [...this.props.value];
  	data_list.splice(idx, 1);
  	this.props.onChange(this.props.name, data_list, true, '');
  }

  handleFormChange = (idx, data, valid) => {
  	const data_list = [...this.props.value];
  	data_list.splice(idx, 1, data);
  	this.props.onChange(this.props.name, data_list, valid, '');
  }

  render () {
  	const {classes, schema, value, show_seperator, label, can_add, can_remove, submit_clicked, editMode} = this.props;

  	return (
  		<div className={classes.cont}>
  			<div className={classes.title}>{ label }</div>
  			<div className={classes.forms_cont}>
  				{value.map((form_data, idx) => <Fragment key={idx}>
  						<div className={classes.form_cont}>
  							<Form
  								submit_clicked={submit_clicked}
  								fieldsOnly
  								data={form_data}
  								schema={schema}
  								editMode={editMode}
  								autoSave
  								autoSaveDelay={10}
  								nested_form
  								onSubmit={(d, valid) => this.handleFormChange(idx, d, valid)}
  							/>

  							{can_remove && editMode &&
                  <div className={classes.removeIcon} onClick={() => this.remove_item(idx)}>
                  	<RemoveIcon color='error' />
                  </div>
  							}
  						</div>

  						{show_seperator && (idx + 1 !== value.length) && <hr className={classes.seperator}/>}
  					</Fragment>
  				)}
  			</div>

  			{can_add && editMode &&
          <div style={{"textAlign": 'center'}}>
          	<Fab disableRipple color='default' size="small" onClick={this.add_item} style={{"marginLeft": 'auto'}}>
          		<AddIcon />
          	</Fab>
          </div>
  			}
  		</div>
  	);
  }
}

export default withStyles(styles)(MultipleForm);
