import {API_HOST_ROOT} from 'config/api-config';
import {get_country_name} from 'constants/countries_en';
import User from 'models/User';

export default class Company {
  id:number;
  country:string;
  name?:string;
  org_no?:string;
  invoicing_currency?:string;
  avatar_url?:string;
  financier_id?:number;
  created_at?:Date
  updated_at?:Date
  limit?:number
  monthly_interest_rate?:number
  sales_representative_id?:number;
  sales_representative?: User;

  users?:Array<User>;
  financer_users?:Array<any>;
  attachments?:Array<any>;
  company_info?:any;
  status?:string;
  bank_account?:any;
  is_active: boolean;

  private _remaining_limit: number;

  constructor (company:any) {
  	const {remaining_limit, id, invoicing_currency, country, avatar_url, financier_id, sales_representative, users,
  		financer_users, company_attachments, status, is_active,  ...c} = company;
  	Object.assign(this, c);
  	this.id = id;
  	this.invoicing_currency = invoicing_currency ? invoicing_currency.toLowerCase() : 'sek';
  	this.country = country;
  	this.avatar_url = avatar_url;
  	this.is_active = is_active;
  	this.financier_id = financier_id;
  	this.sales_representative = sales_representative ? new User(sales_representative) : undefined;
  	this.users = users ? users.map((u:any) => new User(u)) : [];
  	this.financer_users = financer_users ? financer_users : [];
  	this.attachments = company_attachments || [];
  	this.status = status;

  	this._remaining_limit = remaining_limit || 0;

  	Object.assign(this, c);

  }
  get user_entites () {
  	return this.users;
  }


  get avatar () {
  	return this.avatar_url ? API_HOST_ROOT + this.avatar_url : '/assets/img/blank-profile-picture.svg';
  }


  get remaining_limit ():number {
  	return this.is_active ? this._remaining_limit : 0;
  }

  get has_eu_api () {
  	const supported_eu = ["se", "no"];
  	return supported_eu.includes(this.country?.toLowerCase());
  }

  // Can request financing
  get can_fin () {
  	return this.has_eu_api;
  }

  get bank_info_complete () {
  	return this.bank_account && this.bank_account.account_number;
  }

  get country_name () {
  	return get_country_name(this.country)?.title
  }

  /**
   * @deprecated
   */
  get signing_users () { //TODO historical related to Template editor

  	return this.users?.filter((user)=> user.can_sign_alone);
  }

}
