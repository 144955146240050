
export enum AuthMethod {
	'BANKID_SE' = 'bankid_se',
	'BANKID_NO' = 'bankid_no',
	'BANKID_DK' = 'bankid_dk',
	'BANKID_FI' = 'bankid_fi',
	'OTP' = 'sms_otp',
}

export interface AuthMethods {
	[index: string]: AuthMethod[]
}

export interface AuthOption {
	title: string;
	value: AuthMethod;
	image?: any;
}


const COUNTRY_AUTH_METHODS: AuthMethods = {
	"se": [AuthMethod.BANKID_SE],
	"no": [AuthMethod.BANKID_NO, AuthMethod.OTP],
	"fi": [AuthMethod.BANKID_FI, AuthMethod.OTP],
	"dk": [AuthMethod.OTP],
	"gb": [AuthMethod.OTP],
	"default": [AuthMethod.OTP],
};

export const getCountryAuthMethods = (country: string): string[] => COUNTRY_AUTH_METHODS[country] || COUNTRY_AUTH_METHODS.default;

export const auth_opts: Array<AuthOption> = [
	{
		"title": 'Sign with BankID',
		"value": AuthMethod.BANKID_SE,
		"image": {
			"src": "/assets/img/bankid/se/bankid_se.svg",
			"alt": 'BankID SE Logo',
		}
	},
	{
		"title": 'Sign with BankID',
		"value": AuthMethod.BANKID_NO,
		"image": {
			"src": '/assets/img/bankid/no/bankid-logo.svg',
			"alt": 'BankID NO Logo',
		}
	},
	{
		"title": 'MitID',
		"value": AuthMethod.BANKID_DK,
		"image": {
			"src": '/assets/img/icons/mitIdLogo.svg',
			"alt": 'MitId Logo',
		}
	},
	{
		"title": 'Sign with my bank',
		"value": AuthMethod.BANKID_FI,
	},
	{
		"title": 'Sign with verification code',
		"value": AuthMethod.OTP,
	}
];
