import {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import _ from 'lodash';
import {useLocation} from "react-router-dom";
import qs from 'query-string';


export const useToggle = (init_state = false): any => {
	const [toggleState, setToggleState] = useState(init_state);
	function toggle () {
		setToggleState(!toggleState);
	}
	return [toggleState, toggle];
}

export const useTriggerReload = (): any => {
	const [value, setValue] = useState(0);
	const incrementValue = (value: number) => setValue(value + 1);
	return [value, incrementValue];
}
// export const useIncrement = (): any => {
//   const [value, setValue] = useState(0);
//   const incrementValue = () => setValue(value + 1);
//   return [value, incrementValue];
// }

export const usePrevious = (value: any): any => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

export const useArrToHash = ((objArr: Array<any>, key = "id"): any => {
	const [hash, setDealsHash] = useState({});
	useEffect(() => {
		if (!objArr) return;
		const new_hash = objArr.reduce((acc, d) => {
			acc[d[key]] = d;
			return acc;
		}, {});
		setDealsHash(new_hash);
	}, [key, objArr]);
	return hash;
});


export const useDebouncedEffect = (effect: any, deps: any, delay: any) => {
	const didMount = useRef(false);
	useEffect(() => {
		if (didMount.current) {
			const handler = setTimeout(() => effect(), delay);
			return () => clearTimeout(handler);
		}
		else didMount.current = true;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps || [], delay]);
}

export const useDebouncedState = (init_state: any = undefined, delay: number = 500) => {
	const [state, setState] = useState(init_state);
	const debouncedSetState = useMemo(
		() => _.debounce(setState, delay)
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, []);
	return [state, debouncedSetState];
}

export const usePagination = (initPage = 0, initPageSize = 10): { page: number; size: number; setSize: any; setPage: any; } => {
	const [size, setSize] = useState(initPageSize);
	const [page, setPage] = useState(initPage);
	return {size, setSize, page, setPage};
}

export const useDidMountEffect = (func: any, deps: any) => { //Fire hook on deps change but not onComponentMount
	const didMount = useRef(false);
	useEffect(() => {
		if (didMount.current) func();
		else didMount.current = true;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
}

export const useActiveTab = (() => {
	const [activeTab, setActiveTab] = useState(0);
	const location = useLocation();
	useEffect(() => {
		let tab = parseInt(qs.parse(location.hash).tab);
		if (isNaN(tab) && activeTab) tab = activeTab;
		if (!tab) tab = 0;
		setActiveTab(tab);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return [activeTab, setActiveTab];
});


export const useWindowWidth = (delay = 700) => {
	const [width, setWidth] = useState(window.innerWidth);

	useLayoutEffect(() => {
		const handleResize = () => setWidth(window.innerWidth);
		const debouncedHandleResize = _.debounce(handleResize, delay);
		window.addEventListener('resize', debouncedHandleResize);
		return () => {
			window.removeEventListener('resize', debouncedHandleResize);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return width;
}