import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import getClassNames from 'utils/getClassNames';


const styles = () => ({
	"verticalCenter": {
		'alignItems': 'center',
	},
	"horizontalCenter": {
		'justifyContent': 'center',
		'textAlign': 'center',
	}
});

function Row (props) {
	let {classes, spacing, verticalCenter, horizontalCenter, className, ...restProps} = props;
	spacing = spacing ? spacing : 3;

	const classNames = getClassNames(className, classes, {horizontalCenter, verticalCenter});
	return <Grid container {...restProps} spacing={spacing} className={classNames}/>
}

export default withStyles(styles)(Row);