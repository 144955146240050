import Form from './Form/Form';
import FormContext from './FormContext';

export default Form;

const FormProvider = ({children, ...restProps}) => (
	<FormContext.Provider value={restProps}>
		{children}
	</FormContext.Provider>
);

export {
	FormProvider,
}