import {AUTH_LAST_REFRESHED_AT, AUTH_TOKEN_KEY, CURRENT_REFRESH_TOKEN_KEY, MAX_TIME_OF_INACTIVITY, REFRESH_TOKEN_KEY} from "config/constants";

export function setJWT (access_token:string, refresh_token:string) {
	localStorage.setItem(AUTH_TOKEN_KEY, `Bearer ${access_token}`);
	localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);
	localStorage.setItem(AUTH_LAST_REFRESHED_AT, new Date().toISOString());
}

export function getJWT (): string {
	return localStorage.getItem(AUTH_TOKEN_KEY) || '';
}

export function updateAccessToken (access_token: string) {
	localStorage.setItem(AUTH_TOKEN_KEY, `Bearer ${access_token}`);
	localStorage.setItem(AUTH_LAST_REFRESHED_AT, new Date().toISOString());
}

export function getAuthLastRefreshedInSeconds ():number {
	const last_refresh_at = localStorage.getItem(AUTH_LAST_REFRESHED_AT);
	if (!last_refresh_at) return 0;
	const last_refresh_at_date = new Date(last_refresh_at);
	const now = new Date();
	const diff = now.getTime() - last_refresh_at_date.getTime();
	const diffInSeconds = diff / 1000;
	return diffInSeconds;
}

export function getAuthExpired ():boolean {
	//if last refresh at is more than 5 min ago then return true
	const last_refresh_at = localStorage.getItem(AUTH_LAST_REFRESHED_AT);
	if (!last_refresh_at) return !isLoggedIn();
	const diffInSeconds = getAuthLastRefreshedInSeconds();
	return diffInSeconds > MAX_TIME_OF_INACTIVITY;
}

export function getCurrentAuthToken (): string {
	return localStorage.getItem(AUTH_TOKEN_KEY) || '';
}

export function impersonate (new_access_token:string, new_refresh_token:string) {
	localStorage.setItem(CURRENT_REFRESH_TOKEN_KEY, getRefreshToken());
	setJWT(new_access_token, new_refresh_token);
}

export function jwtLogin (access_token:string, refresh_token:string) {
	setJWT(access_token, refresh_token);
}

export function getRefreshToken ():string {
	return localStorage.getItem(REFRESH_TOKEN_KEY) || '';
}
export function getCurrentRefreshToken () {
	return localStorage.getItem(CURRENT_REFRESH_TOKEN_KEY) || '';
}

export function jwtLogout () {
	const current_refresh_token = getCurrentRefreshToken();
	localStorage.setItem(REFRESH_TOKEN_KEY, current_refresh_token);
	localStorage.setItem(AUTH_TOKEN_KEY, '');
	localStorage.setItem(CURRENT_REFRESH_TOKEN_KEY, '');
	localStorage.setItem(AUTH_LAST_REFRESHED_AT, '');
}

export function isLoggedIn () {
	return !!getRefreshToken();
}

export function isOnBehalfOf () {
	return !!getCurrentRefreshToken();
}