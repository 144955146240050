import {Component, Fragment} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Col, Row} from '../Grid';
import Button from '@material-ui/core/Button';
import {DialogContentText, Dialog, DialogTitle, DialogContent, DialogActions} from '@material-ui/core';


const styles = (theme) => ({
	"deleteConfirm": {
		"backgroundColor": theme.palette.error.light,
		"color": 'white',
		'&:hover': {
			"backgroundColor": theme.palette.error.main,
		}
	},
});

class ConfirmDialog extends Component {
	constructor () {
		super();
		this.state = {
			"open": false,
		}
	}

  closeDialog = () => {
  	this.setState({"open": false});
  }

  openDialog = () => {
  	this.setState({"open": true});
  }

  confirm = () => {
  	this.closeDialog();
  	this.props.onConfirm();
  }

  render () {
  	const {open} = this.state
  	const {title, body, classes, renderButton} = this.props;
  	return (
  		<Fragment>
  			{ renderButton(this.openDialog) }

  			<Dialog
  				open={open}
  				onClose={this.closeDialog}
  				aria-labelledby="alert-dialog-title"
  				aria-describedby="alert-dialog-description"
  			>
  				<DialogTitle id="alert-dialog-title">{ title }</DialogTitle>
  				<DialogContent>
  					<DialogContentText id="alert-dialog-description">
  						{ body }
  					</DialogContentText>
  				</DialogContent>

  				<DialogActions>
  					<Row style={{"margin": 0}}>
  						<Col xs={2} />
  						<Col xs={3}>
  							<Button onClick={this.confirm} className={classes.deleteConfirm}>
                  Yes
  							</Button>
  						</Col>
  						<Col xs={2} />
  						<Col xs={3}>
  							<Button onClick={this.closeDialog} color="default" autoFocus>
                  No
  							</Button>
  						</Col>
  						<Col xs={2} />
  					</Row>
  				</DialogActions>
  			</Dialog>
  		</Fragment>
  	);
  }
}

export default withStyles(styles)(ConfirmDialog);
