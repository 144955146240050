import {Fragment, Component} from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';

import CloseIcon from '@material-ui/icons/Close';
import validate from '../Form/lib/validate';
import {withStyles} from '@material-ui/core/styles';
import PreviewLink from '../PreviewLink/PreviewLink';
import {Link} from '@material-ui/core';


const styles = {
	"input_root": {
		"padding": '5px',
	},
	"file": {
		"alignItems": 'center',
		"display": 'flex',
		"marginLeft": '3px'
	}
};

class FileFiled extends Component {
	get fileName () {
		let {value} = this.props;
		if (value.file_data) {
			value = value.file_data;
		}
		return value ? (value.name || value.file_name) : '';
	}

  onChange = (e) => {
  	const {name} = e.target;
  	const value = {"file_data": e.target.files[0]};
  	const {valid, msg} = validate(value, this.props);
  	this.props.onChange(name, value, valid, msg);
  }

  clear = (_event) => {
  	this.props.onChange(this.props.name, '', true, '');
  }

  get url () {
  	const {value,  metadata} = this.props;
  	let {url} = value;
  	if (url && metadata && metadata.host) {
  		url = `${metadata.host}/${url}`;
  	}
  	return url;
  }

  render () {
  	// eslint-disable-next-line no-unused-vars
  	const {onChange, value, helperText, classes, metadata, full_url, template, ...restProps} = this.props;
  	const {fileName} = this; 
  	const {url} = this;

  	if (fileName) {
  		return (
  			<FormControl
  				style={restProps.style}
  				className={restProps.className}
  				required={restProps.required}
  			>
  				<InputLabel>
  					{restProps.label}
  				</InputLabel>

  				<Input
  					value={fileName}
  					className={classes.input_root}
  					inputComponent={(p) => (
  						<Fragment>
  							{url && 
                    <PreviewLink
                    	value={url}
                    	className={classes.file}
                    	preview={true}
                    	hideIcon={false}
                    	full_url={full_url}
                    >
                    	<Typography>
                    		{p.value}
                    	</Typography>
                    </PreviewLink>
  							}

  							{!url &&
                    <Typography>
                    	{ p.value?.length > 20 ? `${p.value?.slice(0, 20)}...` : p.value}
                    </Typography>
  							}

  							<IconButton onClick={this.clear} size='small' style={{"marginLeft": 'auto'}}>
  								<CloseIcon />
  							</IconButton>
  						</Fragment>
  					)
  					}
  				/>
  				<FormHelperText error={true}>{helperText}</FormHelperText>
  				{/* {template && <FormHelperText error={false}>{<Link href={template.url} target="_blank">{template.name}</Link>}</FormHelperText>} */}
  			</FormControl>
  		);
  	}
  	else {
  		return (
  			<>
  				<TextField
  					type='file'
  					onChange={this.onChange}
  					InputLabelProps={{"shrink": true}}
  					// helperText={helperText}
  					{...restProps}
  					inputProps={{
  						"multiple": true,
  					}}
  				/>
  				{template && <FormHelperText error={false}>{<Link href={template.url} target="_blank">{template.name}</Link>}</FormHelperText>}

  			</>
  		);
  	}
  }
}

export default withStyles(styles)(FileFiled);
