import {Fragment} from 'react';
import FormControl from '@material-ui/core/FormControl';
import {withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import displayDT from 'utils/displayDT';
import {dtFormats} from 'config/formats';
import Typography from '@material-ui/core/Typography';
import mapSelectOption from 'utils/mapSelectOption';
import formatNumber from 'utils/formatNumber';
import classnames from 'classnames';
import {API_HOST_ROOT} from 'config/api-config';
import PreviewLink from '../PreviewLink/PreviewLink';


const styles = (theme) => ({
	"label": {
		// fontWeight: 'bold',
	},
	"file": {
		"marginTop": theme.spacing(2),
		"alignItems": 'center',
		"display": 'flex',
		"color": theme.palette.primary.main,
		// padding: '0.4rem 0',
	},
	"multiple_files_cont": {
		"display": 'flex',
		"flexDirection": 'row',
		"flexWrap": 'wrap',
	},
	"left_margin": {
		"marginLeft": '0.6rem'
	}
});

const get_url = ({value}) => {
	let {url} = value;
	return url?.includes(API_HOST_ROOT) ? url : API_HOST_ROOT + url;
}

function FileContents ({value, className, metadata}) {
	if (!value?.url) {
		return null;
	}

	const fileTitle = value.title || value.name;
	const url = get_url({value, metadata});
	if (!url) {
		return null;
	}

	return (
		<PreviewLink
			value={url}
			className={className}
			preview={true}
			hideIcon={false}
		>
			{ fileTitle}
		</PreviewLink>
	);
}

function FileView ({value, classes, multiple, metadata, ui_options}) {
	if (multiple) {
		return (
			<div className={classes.multiple_files_cont}>
				{value.map((file, idx) => <FileContents 
					key={idx}
					value={file}
					className={classnames(classes.file, {[classes.left_margin]: idx !== 0})}
					metadata={metadata}
					ui_options={ui_options}
				/>
				)}
			</div>
		);
	}
	else {
		return <FileContents
			value={value}
			className={classes.file}
			metadata={metadata}
			ui_options={ui_options}
		/>
	}
}


function ViewField (props) {
	let {classes, value, label, type, style, className, noForm, margin, inputProps, InputProps, ...restProps} = props;
	const dtTypes = Object.keys(dtFormats.display);
	const isFile = type === 'file';
  
	if (!type) type = 'text';

	if (type === 'list' || type === 'select') {
		value = mapSelectOption(value, restProps.options)
	}
	else if (type.toLowerCase() === 'password') {
		value = value && value.length ? "•".repeat(value.length) : "";
	}
	else if (type === 'multipleSelect') {
		if (value) {
			value = value.map((v) => mapSelectOption(v, restProps.options)).join(", ");
		}
	}
	else if (dtTypes.includes(type)) {
		value = displayDT(value, type);
	}
	else if (value && value.trim) {
		value = value.trim();
	}

	if (!isFile) {
		const {formatValue, metadata, datatype} = props;
		if (formatValue) {
			value = formatValue ? formatValue(value, metadata) : value;
		}
		else if (['percent', 'currency', 'decimal'].includes(datatype)) {
			value = formatNumber({ 
				"number": value,
				"style": datatype, 
				"local": metadata ? metadata.local : undefined, 
				"currency": metadata ? metadata.currency : undefined, 
			});
		}
	}

	if (noForm) {
		return (
			<Fragment>
				{!isFile &&
          <Typography style={style} className={className}>
          	{value || '--'}
          </Typography>
				}

				{isFile &&
          <FileView
          	value={value}
          	classes={classes}
          	multiple={restProps.multiple}
          	metadata={props.metadata}
          	ui_options={props.ui_options}
          />
				}
			</Fragment>
		);
	}

	return (
		<FormControl margin={margin || 'dense'} style={style} className={className}>
			<InputLabel shrink className={classes.label}>{ label }</InputLabel>
			{!isFile &&
        <Input
        	value= {value || '--'}
        	disableUnderline={true}
        	readOnly={true}
        	multiline
        	{...inputProps}
        	{...InputProps}
        />
			}

			{isFile &&
        <FileView
        	value={value}
        	classes={classes}
        	multiple={restProps.multiple}
        	metadata={props.metadata}
        	ui_options={props.ui_options}
        />
			}
		</FormControl>
	);
}

export default withStyles(styles)(ViewField);
